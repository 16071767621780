.settings {
    .btnView {
        background-color: white;
        border: none;
        margin: 10px;
        padding: 10px 15px;
        border-radius: 3px;
        transition: 0.5s;

        &:hover {
            background-color: $button-blue-h;
            color: white;
        }

        &.active {
            background-color: $button-blue;
            color: white;

            &:hover {
                background-color: $button-blue-h;
                color: white;
            }
        }
    }

    .btnConfig {
        float: right;
        padding: 8px 13px;
        color: white;
        border-radius: 5px;
        background-color: $button-blue;
        border: none;
        margin-left: 25px;
        transition: 0.5s;

        &:hover {
            background-color: $button-blue-h;
        }
    }

    .buttonCol {
        display: flex;
        justify-content: flex-end;
    }

    .tooltip {
        background-color: $button-blue-h;
        color: lighten($color: $button-blue-h, $amount: 25%);
    }

    .viewSpan {
        color: $button-blue;
    }

    .segmentsColorDescription {
        display: flex;
        justify-content: center;
        // background-color: white;
        // border-radius: 5px;
        span {
            padding: 5px 10px;
            border-radius: 15px;
            margin: 0px 5px;
            font-size: 10pt;
            border: solid 1px;
            color: white;
            // background-color: white;
        }
        label {
            margin-right: 10px;
            display: none;
        }
    }

    .matrix-container {
        // border-radius: 5px;
        // background-color: white;
        height: 500px;
        padding: 10px 10px 15px 10px;

        .row {
            margin: 25px 0px 25px 0px;
        }

        .numbers {
            padding: 5px;
            cursor: pointer;
        }

        .totalCol {
            h6 {
                font-size: 13pt;
                font-weight: 600;
            }
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
        }

        //Title Colors
        .r {
            color: black;
            font-weight: 700;
            font-size: 14pt;
        }
        .rr {
            color: black;
        }
        .rp {
            color: black;
        }
        .m {
            // color: #a200ff;
            color: black;
            font-weight: 700;
            font-size: 14pt;
        }
        .mh {
            color: black;
            // color: #b37bd4;
        }
        .mm {
            color: black;
            // color: #9011da;
        }
        .ml {
            color: black;
            // color: #501472;
        }
        .f {
            color: black;
            // color: #ff0037;
            font-weight: 700;
            font-size: 14pt;
        }
        .fh {
            color: black;
            // color: #fa7ca2;
        }
        .fm {
            color: black;
            // color: #aa1441;
        }
        .fl {
            color: black;
            // color: #700f2c;
        }
        // .f{color: #00ccff ; font-weight: 700; font-size: 14pt;}
        // .fh{color: #60dfff}
        // .fm{color: #239fbe}
        // .fl{color: #115263}
    }

    .floating-card {
        background-color: white;
        position: absolute;
        width: 25%;
        top: 75px;
        right: 65px;
        font-size: 10pt;
        border: solid 1px $button-blue;
        border-radius: 5px;

        // h4{}
        // h6{}
        // i{}
        b {
            color: $button-blue;
        }
    }

    .effect-blur {
        -webkit-filter: blur(2px); /*grayscale(20%);*/
        filter: blur(1px); /*grayscale(20%);*/
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }

    .config {
        padding: 25px 0px 25px 0px;
        .row {
            padding: 15px;
            padding-bottom: 35px;
            background-color: darken($color: #ebebeb, $amount: 8%);
        }

        .configRow {
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            padding-right: 5%;
            padding-left: 5%;
            background-color: $lf-gray;
            // margin-bottom: 25px;

            h4 {
                color: white;
                margin-bottom: 0px;
                // align-self: flex-start;
                justify-self: start;
                width: auto;
                margin-right: auto;
            }

            button {
                font-size: 12px;
                padding: 5px 10px;
                margin: 0px 5px;
                border: solid 1px white;
                border-radius: 35px;
                background: $button-blue-h;
                color: white;
                transition: 0.5s;

                i {
                    margin-right: 2px;
                }

                &:hover {
                    background-color: white;
                    color: $button-blue-h;
                }
            }
        }

        .controlsRow {
            padding-top: 25px;
            padding-bottom: 40px;

            .labels {
                margin-top: 8px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }

        .buttonRow {
            display: flex;
            justify-content: center;

            button {
                padding: 5px 10px;
                margin: 5px;
                border: solid 1px $button-blue-h;
                border-radius: 35px;
                background: transparent;
                color: $button-blue-h;
                transition: 0.5s;

                &:hover {
                    background-color: $button-blue-h;
                    color: white;
                }
            }
        }

        .loadingRow {
            h5 {
                width: 100%;
                text-align: center;
            }
        }

        .configurationSavedRow {
            h6 {
                font-size: 16px;
                width: 100%;
                text-align: center;
                color: $button-blue;
            }
        }

        .col-lg-4 {
            margin: auto;
            // padding: 15px;
            // display: flex;
            // flex-flow: column wrap;
        }

        .col-lg-3 {
            margin: auto;
            // padding: 15px;
            // display: flex;
            // flex-flow: column wrap;
        }

        h6 {
            font-size: 1.5rem;
            font-weight: 700;
            width: 100%;
            margin-bottom: 0px;
            text-align: center;
            color: $cortt-blue;
            cursor: default;
            // margin-bottom: 35px;
        }

        p {
            text-align: center;
            margin-bottom: 35px;

            i {
                padding: 2px;
                border: solid 1px #c00798;
                border-radius: 10px;
                font-size: 10px;
                cursor: pointer;
                transition: 0.5s;

                &:hover {
                    background-color: #c00798;
                    color: white;
                    &:before {
                        color: white;
                    }
                }

                &:before {
                    color: #c00798;
                }
            }
        }

        small {
            width: 100%;
            text-align: center;
            color: $button-blue;
            cursor: default;
        }

        .decileContainer {
            display: flex;
            justify-content: space-evenly;
            margin-top: 35px;

            .decile {
                font-size: 10px;
                padding: 3px 6px;
                border: solid 1px $cortt-blue;
                border-radius: 5px;
                transition: 0.5s;
                opacity: 0.5;

                &.low {
                    background-color: lighten($color: $button-blue, $amount: 35%);
                    color: white;
                    border: none;
                }
                &.mid {
                    background-color: $button-blue;
                    color: white;
                    border: none;
                }
                &.high {
                    background-color: darken($color: $button-blue, $amount: 35%);
                    color: white;
                    border: none;
                }
            }
        }

        .barChartContainer{
            width: 100%;
            margin-top: 35px;
        }

        //Colors
        .r {
            // h6 {color: #c00798;}
            p {
                i {
                    border-color:$button-blue;
                    &:hover {
                        background-color: $button-blue;
                        &:before {
                            color: white;
                        }
                    }
                    &:before {
                        color: $button-blue;
                    }
                }
            }
            .input-range__track--active,
            .input-range__slider {
                background-color: $button-blue;
                border: $button-blue;
            }
            .input-range__label--min,
            .input-range__label--max,
            .input-range__label--value {
                color: $button-blue;
            }
            .decile.low {
                background-color: $button-blue;
                color: white;
                border: none;
            }
            .decile.high {
                background-color: darken($color: $button-blue, $amount: 35%);
                color: white;
                border: none;
            }

            .labels {
                span.high {
                    color: darken($color: $button-blue, $amount: 35%);
                }

                span.low{
                    color: $button-blue;
                }
            }
        }
        .f {
            // h6 {color: #904cb8;}
            p {
                i {
                    border-color: $button-blue;
                    &:hover {
                        background-color: $button-blue;
                        &:before {
                            color: white;
                        }
                    }
                    &:before {
                        color: $button-blue;
                    }
                }
            }
            .input-range__track--active,
            .input-range__slider {
                background-color: $button-blue;
                border: $button-blue;
            }
            .input-range__label--min,
            .input-range__label--max,
            .input-range__label--value {
                color: $button-blue;
            }
            .labels {
                span.high {
                    color: darken($color: $button-blue, $amount: 35%);
                }

                span.medium{
                    color: $button-blue;
                }

                span.low{
                    color: lighten($color: $button-blue, $amount: 35%);
                }
            }
        }
        .m {
            // h6 { color: #7e0a51;}
            p {
                i {
                    border-color: $button-blue;
                    &:hover {
                        background-color: $button-blue;
                        &:before {
                            color: white;
                        }
                    }
                    &:before {
                        color: $button-blue;
                    }
                }
            }
            .input-range__track--active,
            .input-range__slider {
                background-color: $button-blue;
                border: $button-blue;
            }
            .input-range__label--min,
            .input-range__label--max,
            .input-range__label--value {
                color: $button-blue;
            }
            .decile {
                &.low {
                    background-color: lighten($color: $button-blue, $amount: 35%);
                    color: white;
                    border: none;
                }
            }
            .decile {
                &.mid {
                    background-color: $button-blue;
                    color: white;
                    border: none;
                }
            }
            .decile {
                &.high {
                    background-color: darken($color: $button-blue, $amount: 35%);
                    color: white;
                    border: none;
                }
            }
            .labels {
                span.high {
                    color: darken($color: $button-blue, $amount: 35%);
                }

                span.medium{
                    color:  $button-blue;
                }

                span.low{
                    color: lighten($color: $button-blue, $amount: 35%);
                }
            }
        }
    }
}

.RfmMatrizClientList {
    .closeClientListRfmMatriz {
        margin-bottom: 35px;

        button {
            background-color: $lf-red;
        }
    }
}
