//Main Container
.segmentCreator{
  margin-left: 10px;
  .row{margin-bottom: 15px;}
}

.getBackRow{
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .navigationButton{
    margin:0px 5px 0px 5px ;
    background-color: $button-blue;
  }

  .cancelButton{
    margin:0px 5px 0px 5px ;
    background-color: $button-red;
  }

  .finButton{
    margin:0px 5px 0px 5px ;
    background-color: $cortt-orange;
  }
}

// Name and Description Form
.nameForm {
  sup {
    font-size: .8vw;
    color: red;
  }

  label{
    color: $button-red;
    font-size: 1vw;
  }

  input{
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $lf-light-nickel;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $lf-light-nickel;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $lf-light-nickel;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color:$lf-light-nickel;
    }
    &:placeholder-shown {
      color: $lf-light-nickel;
    }

    &:focus{
      border-color:$button-blue;
    }
  }

  textarea{
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $lf-light-nickel;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $lf-light-nickel;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $lf-light-nickel;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color:$lf-light-nickel;
    }
    &:placeholder-shown {
      color:$lf-light-nickel;
    }
    &:focus{
      border-color:$button-blue
    }
  }

  span{
    font-size: 10px;
    border: solid $lf-red-l 1px;
    border-radius: 30px;
    padding: 0.5px 5px;
    margin-left: 5px;
    transition: 0.5s;

    &:hover{
      background-color: $lf-red;
      color: white;
    }
  }
}

.customSegmentButtonColor {
  border-bottom: solid 1px $cortt-blue;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 5px;
  transition: .5s;

  button {
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 35px;
    transition: 0.5s;
    transition: 0.5s;
  }

  span{
    transition: 0.5s;
  }

  &:hover {
    button{color: $cortt-blue;}
    background-color: $lf-light-nickel;
  }
}

.colorSelector{
  border-radius: 5px;
  padding: 10px;
}

.colorPreview {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: $lf-red-d;
  margin-top: 5px;
}

//Base Segment Creator -----------------------------------------------------------------------------------------------------------------------------------------------------------
.rowBaseSegments {
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  margin-top: 15px;
  border-radius: 5px;

  .inputRangeContainer {
    margin: 35px 0px;
    padding: 0px 5px;
    //padding: 5px;
  }
}

.baseSegment {
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background-color: $button-blue-h;
    color: white;
  }

  h6{
    margin-bottom: 0px;
  }
}

.baseSegment.active {
  background-color: $button-blue-h;
  color: white;
}

.rfmForm {
  padding-top: 1.5em;

  label {
    color: darken($cortt-blue, 10%);
    font-weight: 600;
  }

  .timePeriodSelect {
    display: inline-block;
    margin-left: 30px;
    label {
      color: $lf-nickel;
    }
  }

  .row {
    text-align: center;
  }

  .clientProfileCol{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  //Clases pertinentes al input range de la creación de segmentos ----------------------------------------------------------------------------------------------------------

  .pruebaInputRange {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  .pruebaRangeSliderContainer {
    transition: left 0.3s ease-out;
    position: relative;
    top: 0;
  }

  .pruebaRangeSlider {
    appearance: none;
    background: lighten($button-blue-h, 15%);
    border: 1px solid $button-blue-h;
    border-radius: 30%;
    cursor: pointer;
    display: block;
    height: 1.8rem;
    width: 1.2rem;
    margin-left: -0.5rem;
    margin-top: -0.45rem;
    outline: none;
    position: absolute;
    top: 0%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;

    &:active {
      transform: scale(1.3);
    }

    &:focus {
      box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
    }
  }

  .pruebaTrack {
    background: $lf-light-nickel;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 1rem;
    position: relative;
  }

  .pruebaRangeActiveTrack {
    position: relative;
    background-color: $button-blue !important;
    height: 1rem;
    border-radius: 0.3rem;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }

  .pruebaLabelContainer {
    position: relative;
    // color: $button-red !important;
  }

  .pruebaMinLabel {
    bottom: -4.5vh;
    position: absolute;
    left: 0;
    color: $button-red !important;
  }

  .pruebaMaxLabel {
    bottom: -4.5vh;
    position: absolute;
    right: 0em;
    color: $button-red !important;
  }

  .pruebaValueLabel {
    position: relative;
    top: -2.3em;
    left: -1.8em;
    border-radius: 35px;
    width: 63px;
    height: 63px;
    text-align: center;
    pointer-events: none;
    opacity: 1;
    display: block;
    transition: 0.3s;
  }
}

.selectAllControl {
  margin-bottom: 25px;
  color: $lf-light-nickel;

  .form-check .form-check-sign::before,
  .form-check .form-check-sign::after {
    border-color: white !important;
  }
}



//Filter selector -----------------------------------------------------------------------------------------------------------------------------------------------------------
.segmentCreatorFilterSelector {
  background-color: white; 
  padding: 25px;
  border-radius: 5px;
  overflow: hidden;
  .card{
    width: 100%;
  }

  .col {
    text-align: center;
    //padding: 3em 3em;
    margin: 10px;
  }

  .hide {
    max-width: 0px !important;
    padding: 0px;
    margin: 0px;
    opacity: 0;
    //display: none;
  }

  .hideDDWSB {
    display: none;
  }

  .dropDownWithSearchBar {
    .row {
      margin: 0px;
    }

    .tables {
      .col {
        background-color: white;
        max-height: 350px;
        overflow-y: scroll;
      }

      .table {
        margin: 10px 0px;
        td {
          border-top: none !important;
          border-bottom: groove 1px !important;
        }

        button {
          padding: 5px 10px;
          background: transparent;
          border: solid 1px;
          border-color: $lf-red-d;
          border-radius: 15px;
          color: $lf-red-d;
          transition: 1s;
          cursor: pointer;

          &:hover {
            background-color: $lf-red-d;
            color: white;
          }
        }
      }

      .btnPager {
        display: inline-block;
        border-color: gray;
        border-radius: 5px;
        color: gray;
        margin: 15px 25px;
        padding: 5px 20px;
        transition: 0.5s;

        &:hover {
          background-color: gray;
          color: white;
        }
      }

      .tableSelectedProducts {
        h4 {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        td{
          button{
            margin-top: 5px;
            &.active{
              background-color: $lf-red-d;
              color:white;
              &:hover{
                background-color: $lf-red-d;
                color:white;
              }
            }
          }
        }
      }
    }

    input {
      margin-top: 15px;
      margin-bottom: 15px;
      border-color:$button-blue;
      &:focus{border-color:$lf-red}

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $lf-light-nickel;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $lf-light-nickel;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $lf-light-nickel;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $lf-light-nickel;
      }
      &:placeholder-shown {
        color: $lf-light-nickel;
      }
    }
  }
}

.noPadNoMar {
  padding: 0px !important;
  margin: 0px;
  border-bottom: groove 1px white;

  h3 {
    margin: 0px;
  }
}

.colBtnGetBack {
  position: relative;
  float: right;
}

.categoryNavBar{
  a{
    transition:.5s;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
      background-color: white;
      color: $cortt-blue !important;
    }
  }
}

//Segment Preview -----------------------------------------------------------------------------------------------------------------------------------------------------------

.segmentPreview {
  background-color: darken($color: $cortt-blue, $amount: 8%);
  padding: 25px;
  border-radius: 5px;
  transition: 1s;
  margin: 15px 0px;

  &.active {
    display: block;
    right: 0%;
  }

  .mainCard {
    margin-bottom: 0px !important;
    background: transparent;
    border: solid 2px lighten($cortt-blue, 10%);
    box-shadow: none;

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-8 {
      padding: 1.5em 2em;
    }

    .previewSubCards {
      h3 {
        text-align: center;
        border-bottom: groove 0.5px white;
        margin-bottom: 10px;
      }

      h2,
      h4,
      h5,
      h6 {
        text-align: center;
        margin-bottom: 10px;
      }

      .justified {
        text-align: justify;
      }
    }
  }

  .btnCreateSegment {
    margin-top: 5px;
    padding: 1.5em 3em;
    color: $lf-red-d;
    background: transparent !important;
    border: 1px solid $lf-red-d;
    border-radius: 45px;
    transition: 0.5s;

    &:hover {
      background: white !important;
    }
  }

  .minSegmentName {
    h5 {
      padding: 5px;
      background-color: $lf-red-d;
      border-radius: 10px;
    }
  }

  .minFilterName {
    text-align: center;
    padding: 5px;
    background-color: $button-red; //orange
    border-radius: 10px;
    font-weight: 400;
    font-size: 0.8125rem;
    margin-top: 10px;
  }
}

.floatingPreview {
  width: 100%;
  background-color: white;
  padding: 15px 5px;
  border-radius: 5px;
  transition: 1s;

  &.active {
    right: 2%;
  }

  .maincard {
    background: transparent;
    border: solid 2px $lf-light-nickel;
    margin-bottom: 0px;

    h1,h2,h3,h5,h6,p {
      color: $cortt-blue !important;
    }

    h4 {
      color: $cortt-orange;
      font-weight: 550;
    }

    i{margin: 0px 25%}

    .btnCreateSegmentCol{
      display: flex;
      flex-flow: row-reverse;
    }
  }

  .btnCreateSegment {
    color: $cortt-blue;
    background: transparent !important;
    border: 1px solid $cortt-blue;
    border-radius: 45px;
    transition: 0.5s;
    padding:10px 40px;

    &:hover {
      background: white !important;
    }
  }

  .minSegmentName {
    h5 {
      padding: 5px;
      background-color: $button-red;
      border-radius: 10px;
      color: white !important;
      text-align: center;
    }
  }

  .minFilterName {
    h5 {
      padding: 5px;
      background-color: $cortt-orange;
      border-radius: 10px;
      text-align: center;
      color: white !important;
    }
  }

  .clientProfile {
    i {
      color: $cortt-orange;
    }
  }

  .nameAndIcon{
    i{
      font-size: 2em;
      margin: 0px 10px;
    }

    h4{
      display: inline;
    }
  }
}

.stdOutDataB {
  color: $lf-red-d !important;
}

//Otros (Boton para regresar de pantalla, Perfil del cliente) -----------------------------------------------------------------------------------------------------------------------------------------------------------
.btnGetBackToMySegments {
  border-radius: 10px;
  background-color: white;
  color: $cortt-blue;
  padding: 8px;
  width: 100%;
  transition: 0.3s;
  box-shadow: black;

  &:hover {
    background-color: $lf-red-d;
    color: white;
  }
}

.btnOpenPreview {
  position: fixed;
  right:-1em;
  top: 10em;
  z-index: 10000; // above 1050 to cover the Category Navbar 
  cursor: pointer;

  button {
    color: white;
    background: $cortt-orange !important;
    background-image: none !important;
    border: 1px solid $cortt-orange;
    transition: 0.5s;
    padding: 8px 20px 8px 14px;
    font-weight: 600;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      background: white !important;
      color: $cortt-orange;
      background-image: none !important;
    }
  }

  i{
    font-size: 1.3em;
  }

  &.active {
    button {
      color: $cortt-orange;
      background: white !important;
      &:hover {
        background: $cortt-orange !important;
        color: white;
      }
    }
  }
}

.btnFinSegment {
  color: white;
  background: $cortt-orange !important;
  border: 1px solid $cortt-orange;
  border-radius: 45px;
  margin-top: 25px;
  transition: 0.5s;
  padding:10px 40px;

  &:hover {
    background: white !important;
    color: $cortt-orange;
  }

  &:active{
    color: white;
    background: $cortt-orange !important;
    &:hover {
      background: white !important;
      color: $cortt-orange;
    }
  }

  &:focus{
    color: white;
    background: $cortt-orange !important;
    &:hover {
      background: white !important;
      color: $cortt-orange;
    }
  }
}

.clientProfile {
  text-align: center;

  i {
    font-size: 6em;
    margin-bottom: 15px;
    color: $lf-red;
  }

  b {
    font-weight: 700 !important;
    color: $lf-red;
  }

  &.darken {
    .card {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.inlineInputSelector {
  div {
    display: inline-block;
    margin: 0px 2%;
    cursor: pointer;
    transition: 0.5s;
    padding: 3%;
    border-radius: 10px;

    i {
      color: white;
      font-size: 16pt;
    }

    &:hover {
      background-color: darken($color: $cortt-blue, $amount: 15%);
    }
  }
}

.noMargin{
  margin: 0px;
}

.noPadding{
  padding:0px
}

.noPadAndMarg{
  margin: 0px;
  padding:0px;
}

.white{
  color:white;
  background-color: white;
}

//Responsive --------------------------------------------------------------------------------------------------------------------------------------------------------

@media screen and (max-width: 992px){

}

@media screen and (max-width: 768px){

}