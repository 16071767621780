.segmentTitleRow {
    margin-bottom: 25px;

    .title {
        display: flex;
        align-items: center;
        align-content: center;

        .btnOpenRfmMatriz {
            float: right;
            padding: 8px 13px;
            color: white;
            border-radius: 5px;
            background-color: $button-blue;
            border: none;
            margin-left: 25px;

            &:hover {
                background-color: $button-blue-h;
            }
        }

        .btnBack {
            float: right;
            padding: 8px 13px;
            color: white;
            border-radius: 5px;
            background-color: $button-red;
            border: none;
            margin-left: 25px;

            &:hover {
                background-color: $button-red-h;
            }
        }
    }
}

.btnBack {
    float: right;
    padding: 8px 13px;
    color: white;
    border-radius: 5px;
    background-color: $button-red;
    border: none;
    margin-left: 25px;

    &:hover {
        background-color: $button-red-h;
    }
}

.btnOpenRfmMatriz {
    float: right;
    padding: 8px 13px;
    color: white;
    border-radius: 5px;
    background-color: $button-blue;
    border: none;
    margin-left: 25px;

    &:hover {
        background-color: $button-blue-h;
    }
}

.globalSummary {
    margin-bottom: 25px;
    text-align: center;

    .value {
        font-size: 2.5vw;
        color: $cortt-green;
    }

    .title {
        font-size: 1.8vw;
        color: $cortt-blue;
    }

    .explain {
        font-size: 0.8vw;
        color: $lf-gray-l;
    }
}

.segmentCardContainer {
    margin-bottom: 15px;

    .segmentCard {
        margin-bottom: 15px;
    }
}

.segmentCardHeader {
    background-color: $lf-gold-l;
    color: white;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;

    &.segment1 {
        background-color: $segment-retain !important;
        button {
            background-color: $segment-retain !important;
        }
    }
    &.segment2 {
        background-color: $segment-increaseM !important;
    }
    &.segment3 {
        background-color: $segment-increaseF !important;
    }
    &.segment4 {
        background-color: $segment-bringB !important;
    }
    &.segment5 {
        background-color: $segment-ocasionals !important;
    }

    span {
        font-size: 4.5vh;
        font-weight: 700;
    }

    .icon {
        font-size: 3em;
        font-weight: 400;
    }
}

.segmentCardBody {
    div {
        padding: 5px 0px;
        text-align: center;

        p {
            margin: 0px;

            &.explain {
                color: #9bafbaff;
            }
        }
    }

    .segment1 {
        .btnDetail {
            background-color: $segment-retain !important;
        }

        .btnExport {
            background-color: $segment-retain !important;
        }
    }
    .segment2 {
        .btnDetail {
            background-color: $segment-increaseM !important;
        }

        .btnExport {
            background-color: $segment-increaseM !important;
        }
    }
    .segment3 {
        .btnDetail {
            background-color: $segment-increaseF !important;
        }

        .btnExport {
            background-color: $segment-increaseF !important;
        }
    }
    .segment4 {
        .btnDetail {
            background-color: $segment-bringB !important;
        }

        .btnExport {
            background-color: $segment-bringB !important;
        }
    }
    .segment5 {
        .btnDetail {
            background-color: $segment-ocasionals !important;
        }

        .btnExport {
            background-color: $segment-ocasionals !important;
        }
    }

    button {
        width: 100%;
        margin-bottom: 10px;
        border: none;
        border-radius: 5px;
        padding: 10px 0px;
        color: white;
        transition: 0.5s;

        &:hover {
            transform: scale(1.05, 1.05);
        }

        // &.btnDetail{
        //     background-color: $lf-red;
        // }

        &.btnExport {
            background-color: $lf-red-l;
        }
    }
}

.segmentDashboard {
    &.blur {
        filter: blur(1px);
    }
}

.backToSegmentsButton {
    background-color: $button-red;
    border: none;
    transition: 0.5s;

    &:hover {
        background-color: $button-red-h;
        transform: scale(1.1);
    }
}

.addFilterButtonRow {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;

    button {
        background-color: $button-blue !important;
        border: none;
        width: 30%;
        padding: 10px 0px;
        transition: 0.5s;

        &:hover {
            transform: scale(1.05);
            background-color: $button-blue-h !important;
        }
    }
}

//SEGMENT DETAIL
.segmentDetail {
    &.blur {
        filter: blur(1px);
    }
}

.segmentNavigation {
    margin-bottom: 15px;

    .title {
        background: none;
        border: none;
        color: $cortt-blue;
        span {
            font-size: 5vh;
        }
        i {
            margin-left: 30px;
        }
        &:focus {
            box-shadow: none;
        }
    }

    .menu {
        width: 80%;
        background-color: $button-blue;
        //color: white !important;
    }

    .item {
        color: white;
        transition: 0.3s;
        &:hover {
            color: $cortt-blue;
        }
    }

    .start-end {
        display: flex;
        flex-flow: column-reverse;
    }
}

.segmentTabs {
    margin-bottom: 15px;

    i {
        margin-right: 5px;
    }

    .nav-item {
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            color: lighten($color: $lf-red, $amount: 25%);
        }
    }

    .nav-link {
        border: none !important;
        &.active {
            background-color: transparent !important;
            color: $lf-red;
            border-bottom: solid 2px $lf-red !important;
        }
    }
}

.detailGenderSummary {
    width: 100%;

    .genderCard {
        p {
            margin: 0px;
        }

        .icon {
            font-size: 3em;
            margin: 0px;
        }

        .mainData {
            font-size: 1.3em;
            font-weight: 700;
        }

        .avgTicket {
            color: $lf-gray-l;
        }
    }
}

.doughnut {
    width: 100%;
}

.pointChart {
    .subdata {
        color: $lf-gray-l;
        font-size: 0.9em;
    }
    .chartTitle {
        padding-bottom: 10px;
    }
}

.treeMapChart {
    margin-top: 10px;
    //background-color: #596a77;
    //padding: 25px;
    border-radius: 15px;

    a {
        color: white !important;
    }

    .row {
        padding: 15px;
        border-bottom: groove 1px white;
    }

    .iCgSaw a::after {
        z-index: 0;
    }

    .dropDownButton {
        //background: transparent !important;
        background-color: white;
        color: $cortt-blue;
        background-image: none;
        transition: 0.5s;

        &:hover {
            background-image: none !important;
            background-color: $lf-gray !important;
            color: white;
        }
    }

    .configCol {
        display: flex;

        .dropdown {
            margin: 0px 10px;
        }
    }
}

.pager-button {
    border-radius: 20px;
    border: solid 1px $cortt-blue;
    background-color: transparent !important;
    cursor: pointer;
    padding: 10px 25px 10px 25px;
    color: $cortt-blue;
    margin-right: 10px;
    margin-top: 10px;
    transition: 0.5s;

    &:hover {
        color: white;
        background-color: darken($cortt-blue, 5%) !important;
    }
}

.crmSegments {
    thead {
        background-color: $cortt-blue;
        color: white;
    }

    button {
        background: transparent;
        border: solid 1px white;
        padding: 5px 15px;
        border-radius: 10px;
        color: white;
        margin: 0px 10px;
        cursor: pointer;
        transition: 0.5s;
        border-color: $button-blue-l;
        i{
            color: $button-blue-l
        }
        &:hover {
            background-color: $button-blue-l;
            color: white;
            i {
                color: white;
            }
        }
    }
}

.crmSegmentsBar {
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    margin-bottom: 2em;

    .dropdown {
        margin-right: 15px;
        button {
            background-color: transparent !important;
            color: $lf-gray;
            padding: 5px 30px;
        }
    }

    .btnExport {
        background-color: $button-blue !important;
        color: white;
        padding-left: 30px;
        padding-right: 30px;
        border: none;
        transition: 0.5s;

        &:hover {
            background-color: $button-blue-h;
        }
    }

    label {
        margin: 0px 10px;
    }
}

.addFilterButton {
    background-color: $button-blue !important;
    border: none;
    // width: 30%;
    // padding: 10px 0px;
}

.salesPerMonthCol {
    height: 150px;

    .salesPerMonthCard {
        height: 150px;
        .salesPerMonthCardBody {
            height: 150px;

            .salesPerLineContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                canvas {
                    height: 100px !important;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .pointChart {
        margin-bottom: 15px;
    }

    .globalSummary {
        .value {
            font-size: 4vw;
        }

        .title {
            font-size: 2.5vw;
        }

        .explain {
            font-size: 1.2vw;
        }
    }

    .segmentNavigation {
        .title {
            span {
                font-size: 3.5vh;
            }
        }

        .start-end {
            margin-bottom: 15px;
        }
    }

    .backToSegmentsButton {
        font-size: 1.5vw;
    }

    .addFilterButton {
        font-size: 1.5vw;
    }
}
