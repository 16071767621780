.exportsList {
  input[type="text"] {
    &:focus {
      border-color: $lf-red;
    }
    margin-bottom: 15px;
    border-color: $button-blue;
  }

  p {
    margin-right: 1.5em;
  }

  .btns {
    background: transparent;
    border: solid 1px white;
    padding: 5px 15px;
    border-radius: 10px;
    color: white;
    margin: 0px 10px;
    cursor: pointer;
    transition: 0.5s;
    border-color: $button-blue-l;
    &:hover {
      background-color: $button-blue-l;
      color: white;
      i{
        color: white;
      }
    }
    a {
      font-style: inherit;
      color: inherit;
      background-color: transparent;
      font-size: inherit;
      text-decoration: none;
      font-variant: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-family: inherit;
    }
  }

  i {
    color: $button-blue-l;
    transition: .5s;
    &.iconBig {
      margin-right: 10px;
    }
  }

  li{
    margin-bottom: 25px;
  }

  .buttonTooltip{
    background-color: lighten($color: #f5e4e4, $amount: 25%) ;
    color: $button-blue;
  }

  .dropdownCol{
    display: flex;
    // align-content: flex-end;
    justify-content: flex-start;
    align-items: center;
    div{
      margin-left: 10px;
      .dropdown{
        button{
          background-color: $button-blue;
          border: none;
        }
        .dropdown-menu{
          background-color: $button-blue;
         button{
          color: white;
          &:hover{
            background-color: $button-blue-h;
          }
         }
        }
      }
    }
  }

  .exportsTable{
    thead{
      background-color: $cortt-blue;
      color: white;
    }
  }
}

.exportsClientList {
  table {
    tr {
      td,
      th {
        text-align: center;
      }
    }
  }
}

.exportCreator {
  max-width: 50%;
  min-width: 50%;
  position: fixed;
  top: 25%;
  left: 35%;
  transition: 0.5s;
  z-index: 2;

  .card {
    //background-color: darken($button-blue, 10%);
  }

  h4 {
    //color: darken($bluegray, 5%);
    margin-bottom: 5px;
    margin-top: 5px;
  }

  b {
    //color: darken($bluegray, 5%);
    font-weight: 600;
  }

  button {
    float: right;
    padding: 10px 20px;
    margin: 0px 5px;
    background: none;
    border: solid 1px white;
    border-radius: 25px;
    color: $cortt-blue;
    transition: 0.5s;

    &:hover {
      background-color: $cortt-blue;
      color: white;
    }
  }

  input[type="text"] {
    margin-bottom: 10px;
  }

  input,
  textarea {
    &:focus {
      border-color: $lf-red;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $lf-light-nickel;
      opacity: 0.7;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $lf-light-nickel;
      opacity: 0.7;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $lf-light-nickel;
      opacity: 0.7;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $lf-light-nickel;
      opacity: 0.7;
    }
    &:placeholder-shown {
      color: $lf-light-nickel;
      opacity: 0.7;
    }
  }
}

.deleteExportModal{
  text-align: center;
  button{
    padding: 10px 20px;
    margin: 0px 5px;
    background: none;
    border: solid 1px white;
    border-radius: 25px;
    color: #333f48ff;
    border: 1px solid #333f48ff;
    transition: 0.5s;
  }
}