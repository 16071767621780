//Fonts ----------------------------------------------------------------------------------------------------------
@font-face {
	font-family: 'rhw';
	src:url('../fonts/rhw-regular.ttf') format('truetype');   
}


// GLOBAL CLASSES
body{
  font-family: 'rhw';
}

.noPadding{
  padding: 0px !important;
}

.noBorder{
  border-color: transparent;
}

.noMargin{
  margin: 0px;
}

.noRadius{
  border-radius: 0px !important;
}

.stdOutData{
    color:$cortt-green;
}

.btnGetBack{
  border: none;
  border-radius: 5px;
  background-color: $lf-gray-l;
  padding: 10px 15px;
  color:white;
}

.singleBigChart{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.blur{
  filter: blur(1px);
}






