.logoCortteza {
    margin-bottom: 2em;
}

.bucketLink {
    cursor: pointer;
    color: $cortt-green !important;
    margin: 0 10px 0px 10px;
    font-size: 14pt;
    display: inline-block;
    transition: .5s;
    border-radius: 2.5px;
    padding: 2.5px 10px;

    &:hover{
        transform: scale(1.2);
    }
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page {
        min-height: 100vh;
        height: auto;
    }
}

.main-panel {
    position: relative;
    float: right;
    width: 95.5vw;
    min-height: 100vh;
    transition: 0.5s;

    > .content {
        background-color: rgb(235, 235, 235);
        padding: 12vh 2.5vw 10vh 2.5vw;
        min-height: 100vh;
    }

    > .navbar {
        margin-bottom: 0;
    }

    .header {
        margin-bottom: 50px;
    }
}

.sidebar {
    height: 100%;
    width: 70px;
    //padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background-size: cover;
    background-position: center center;
    display: block;
    overflow: hidden;
    color: white;

    .sidebar-wrapper {
        width: 100%;
        min-height: 100%;
        max-height: calc(100vh - 705px);
        z-index: 4;
        position: relative;
        overflow: hidden;
    }

    .logo{
        padding: 15px;
        margin-bottom:0px;
    }

    .nav-wrapper{
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        min-height: 90vh;
    }

    .nav {
        margin-top:0px !important;
        display: block;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-content: center;

        .nav-link{
            display: contents !important;
        }

        li {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0px;
            padding: 15px 0px;
            transition: .5s;
            &.active {
                background-color: lighten($color: $cortt-blue, $amount: 25%);
                a{opacity: 1 !important;}
                i{opacity: 1 !important;}
                p{opacity: 1 !important;}
            }
            &:hover {
                a{opacity: 1 !important;}
                i{opacity: 1 !important;}
                p{opacity: 1 !important;}
            }
        }

        p {
            margin: 0;
            color: white;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            font-weight: 500;
            font-size: 11pt;
            //opacity: 0.6;
        }

        i {
            font-size: 20px;
            float: left;
            line-height: 30px;
            width: 34px;
            color: white;
            text-align: center;
            position: relative;
            opacity: 0.6;
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    &[data="default"] {
        background-color: $cortt-blue;
    }
    &[data="default.css"] {
        background-color: $cortt-blue;
    }
    &[data="lightTheme.css"] {
        background-color: $lf-red;
    }

    .footer{
        //position: absolute;
        //bottom: 0px;
        //padding: 15px;

        li{
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: center;
            //margin: 1em 0px;
            padding: 15px 0px;
        }
    }
}


@media screen and (max-width: 991px) {
    .sidebar {
        position: fixed;
        display: block;
        top: 0;
        height: 100%;
        //width: 260px;
        right: auto;
        left: 0;
        margin: 0;
        border-radius: 0;
        z-index: 1032;
        visibility: visible;
        overflow-y: visible;
        padding: 0;
        @include transition(0.5s cubic-bezier(0.685, 0.0473, 0.346, 1));
        @include transform-translate-x(-80px);

        .logo {
            a.logo-normal {
                opacity: 0;
                @include transform-translate-x(-25px);
            }
        }
    }

    .main-panel {
        width: 100%;
        .content {
            padding: 20vh 2.5vw 10vh 2.5vw;
            min-height: 150vh;
        }
    }

    .nav-open {
        .main-panel {
            right: 0;
            transition: 0.5;
            @include transform-translate-x(25px);
        }

        .sidebar {
            @include transform-translate-x(0px);
            height: 100%;
            width: 70px;
            padding: 15px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            background-size: cover;
            background-position: center center;
            display: block;
            overflow: hidden;
            color: white;
        }

        body {
            position: relative;
            overflow-x: hidden;
        }

        .menu-on-right {
            .main-panel {
                // @include transform-translate-x(-50px);
            }

            .navbar-collapse,
            .sidebar {
                @include transform-translate-x(0px);
            }

            #bodyClick {
                right: 50px;
                left: auto;
            }
        }
    }

    .menu-on-right {
        .sidebar {
            left: auto;
            right: 0;
            @include transform-translate-x(50px);
        }
    }

    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 1;
        top: 0;
        right: 0;
        left: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
        background-color: transparent;
        @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }
    .navbar .dropdown-menu {
        position: static !important;
    }
}

@media screen and (max-width: 768px) {
    .main-panel .content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .main-panel {
        width: 100%;
        .content {
            padding: 30vh 2.5vw 10vh 2.5vw;
            min-height: 100vh;
        }
    }
}
