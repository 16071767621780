.campaignList {
    .titleRow {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;

        svg {
            width: 25px;
            height: 25px;

            margin-right: 5px;
        }

        button {
            background-color: $button-blue;
            transition: 0.5s;
            border: none;

            &:hover {
                transform: scale(1.05);
                background-color: $button-blue-h;
            }
        }
    }

    .configBar {
        margin-top: 25px;

        .dropdownsCol {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 0px;

            button {
                color: $lf-gray-l;
                background-color: transparent;
                margin: 0px 15px 0px 5px;

                i::before {
                    font-size: 0.8em;
                }
            }

            .typeIcon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }

        .progressCol {
            p {
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .cuotaEmail {
                font-size: 10pt;
            }
            .progress {
                background-color: $lf-gray-l;
                // height: 100%;
            }
        }

        .cardStyleSelectionCol {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            span {
                cursor: pointer;
                margin: 0px 15px;
                font-size: 1.4em;
                color: $lf-gray;
                transition: 0.5s;

                i {
                    &.active {
                        color: $lf-red-l;
                    }
                }

                &:hover {
                    i {
                        color: $lf-red-l;
                    }
                }
            }
        }
    }
}

$pink: #dd0337;
$green: rgb(114, 185, 105);
$celeste: rgb(87, 187, 218);
$amarillo: rgb(211, 93, 93);
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.campaign-container {
    flex: 0 0 100%;
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-start;
    margin-top: 35px;
    h4 {
        align-self: flex-start;
    }

    .campaign-card {
        flex: 0 0 22%;
        display: flex;
        flex-flow: wrap column;
        background-color: white;
        border-radius: 10px;
        align-content: center;
        justify-content: flex-start;
        margin: 0px 1.5% 25px 1.5%;
        padding: 15px;
        min-height: 200px;

        .campaignTitle {
            text-align: center;
            margin-bottom: 2%;

            p {
                color: $lf-light-nickel;
                margin-bottom: 0px;
            }

            small {
                color: $lf-red-l;
            }
        }

        .sent {
            display: flex;
            min-width: 80%;
            flex-flow: row wrap;
            justify-content: center;
            .env {
                margin-bottom: 10px;
                padding: 5px;
                border-bottom: solid 1px gray;
                flex: 0 0 80%;
                text-align: center;
                color: $cortt-blue;
            }
            h5 {
                width: 100%;
                text-align: center;
                align-self: flex-start;
                margin-bottom: 0px;
            }
            .stats {
                flex: 0 0 80%;
                display: flex;
                justify-content: center;
                align-content: center;
                margin-bottom: 25px;
            }

            .list {
                display: flex;
                flex: 0 0 80%;
                flex-flow: wrap column;
                h4 {
                    margin: 0px;
                }

                .entregados {
                    width: 100%;
                    margin-bottom: 10px;
                    padding: 5px;
                    justify-content: center;
                    align-content: center;
                    text-align: center;
                    border-bottom: dotted 1px gray;
                    h4,
                    h6 {
                        color: $lf-red;
                    }
                    small {
                        background-color: $lf-red;
                        color: white;
                        padding: 1px 5px 1px 5px;
                        border-radius: 5px;
                    }
                }

                .abre {
                    width: 100%;
                    margin-bottom: 15px;
                    padding: 5px;
                    display: flex;
                    flex-flow: wrap row;
                    justify-content: space-around;
                    text-align: center;
                    border-bottom: dotted 1px gray;

                    .abiertos {
                        padding: 5px;
                        //flex: 0 0 50%;
                        h4,
                        h6 {
                            color: $lf-gray-d;
                        }
                        small {
                            background-color: $lf-gray-d;
                            color: white;
                            padding: 1px 5px 1px 5px;
                            border-radius: 5px;
                        }
                    }

                    .rebotados {
                        padding: 5px;
                        h4,
                        h6 {
                            color: $lf-gray-l;
                        }
                        small {
                            background-color: $lf-gray-l;
                            color: white;
                            padding: 1px 5px 1px 5px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        .inProcess {
            flex: 0 0 65%;
            display: flex;
            flex-flow: wrap column;
            justify-content: space-evenly;
            align-content: center;

            i {
                &::before {
                    color: $lf-gray;
                    font-size: 11em;
                }
                text-align: center;
            }
        }

        .detailButton {
            flex: 0 0 auto;
            display: flex;
            flex-flow: wrap column;
            justify-content: center;
            align-content: center;
            margin-bottom: 15px;

            button {
                border: solid 1px $lf-red;
                padding: 5px 15px;
                color: $lf-red;
                border-radius: 2px;
                background: none;
                transition: 0.5s;

                &:hover {
                    background-color: $lf-red;
                    color: white;
                }
            }
        }

        .mainPanel {
            margin: 10px 0px 10px 0px;
        }

        .smsSent {
            display: flex;
            flex-flow: wrap column;
            align-items: center;
            justify-content: center;
            align-content: center;
            margin-bottom: 25px;
            div {
                display: flex;
            }
            p {
                margin: 0px;
                text-align: center;
            }
            span {
                font-size: 1.5em;
                i {
                    font-size: 1.6em;
                }
            }
            button {
                background: none;
                border: solid 1px $button-blue;
                border-radius: 5px;
                color: $button-blue;
                font-size: 0.8em;
                padding: 2px 5px;
            }
        }
    }

    .loading {
        width: 100%;
        text-align: center;
    }

    .noCampaignMessage {
        margin-top: 15vh;
        padding: 15px;
        color: $lf-gray-l;
        width: 100%;
        text-align: center;
    }

    .campaignTable {
        thead{
            background-color: $cortt-blue;
            color: white;
        }
        tr{
            td{
                b{
                    color:$cortt-green
                }
            }
        }
        button {
            background: transparent;
            border: solid 1px white;
            padding: 5px 15px;
            border-radius: 10px;
            color: white;
            margin: 0px 10px;
            cursor: pointer;
            transition: 0.5s;
            border-color: $button-blue-l;;

            i {
                color: $button-blue-l;
                transition: 0.5s;
                &.iconBig {
                    margin-right: 10px;
                }
            }

            &:hover {
                transition: 0.5;
                background-color: $button-blue-l;
                i {
                    color: white;
                }
            }
        }
    }
}
