.segmentList {
  input {
    border-color: $button-blue;

    &:focus {
      border-color: $lf-red-d;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $lf-gray-l;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $lf-gray-l;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $lf-gray-l;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $lf-gray-l;
    }
    &:placeholder-shown {
      color: $lf-gray-l;
    }
  }

  .buttonCol {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .dropdownCol {
    display: flex;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: flex-end;
    div {
      margin-left: 10px;
      .dropdown {
        button {
          background-color: $button-blue;
          border: none;
        }
        .dropdown-menu {
          background-color: $button-blue;
          button {
            color: white;
            &:hover {
              background-color: $button-blue-h;
            }
          }
        }
      }
    }
  }

  .segmentTable {
    thead {
      background-color: $cortt-blue;
      color: white;
    }
    tbody {
      tr {
        td {
          button {
            background: transparent;
            border: solid 1px white;
            padding: 5px 15px;
            border-radius: 10px;
            color: white;
            margin: 0px 10px;
            cursor: pointer;
            transition: 0.5s;
            border-color: $button-blue-l;

            i {
              color: $button-blue-l;
              transition: 0.5s;
              &.iconBig {
                margin-right: 10px;
              }
            }

            &:hover {
              transition: 0.5;
              background-color: $button-blue-l;
              i {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.segmentListItem {
  margin-bottom: 25px;
  button {
    margin-bottom: 8px;
    padding: 5px 15px;
    color: $button-blue;
    background: transparent;
    border: solid 1px white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;
    span {
      transition: 0.5;
      margin-left: 5px;
    }

    &.btnViewDetail {
      display: inline-block;
    }

    &:hover {
      //color: white;
      background-color: lighten($color: $button-blue-h, $amount: 35%);
    }

    &.fullW {
      width: 100%;
    }

    &.midW {
      width: 98%;
    }
  }

  .buttonsCol {
    display: flex;
    flex-flow: row-reverse;
  }

  li {
    padding: 3px;
    color: $filter-green;
    background-color: $filter-green-background;
    text-align: center;
    margin: 5px 0px;
    border-radius: 2px;
  }

  h3,
  h4 {
    margin-bottom: 0px;
  }

  h4 {
    max-width: 50%;
    display: inline-block;
    margin-right: 15px;
  }

  h6 {
    text-align: center;
  }

  hr {
    opacity: 0.5;
  }

  .icon {
    font-size: 1.5vw;
  }

  .segmentDescription {
    border: none;
  }

  .sbList {
    .col {
      padding: 0px 7.5px;
    }
    li {
      font-size: 0.8vw;
      &.all {
        font-size: 1em;
        margin: 0px;
      }
    }
  }

  .tooltip {
    color: $button-blue;
    background-color: lighten($color: $button-blue-h, $amount: 35%);
  }
}

.dateOfData {
  display: flex;
  flex-flow: reverse;
  justify-content: flex-end;
  align-items: center;
  align-content: center;

  label {
    margin: 0px 10px;
  }

  span {
    padding: 5px 5px;
    background-color: lighten($color: $button-blue-h, $amount: 35%);
    color: $button-blue;
    border-radius: 2px;
  }
}

.newSegmentButton {
  color: white;
  background-color: $button-blue;
  border: none;
  transition: 0.5s;

  span {
    display: inline;
  }

  &:hover {
    transform: scale(1.05);
    background-color: $button-blue-h;
  }

  &:active {
    background: white !important;
    color: $lf-red-d !important;
    background-image: none !important;

    &:hover {
      background: white !important;
      color: $lf-red-d !important;
      background-image: none !important;
    }
  }
}

.customSegmentMinSum {
  margin-top: 20px;

  .card {
    border: none;
  }

  tbody {
    font-weight: bold !important;
    .stdOut {
      color: inherit !important;
      font-size: 1.5em;
      opacity: 0.8;
    }
  }
}

.customSegmentMinSumHorizontal {
  text-align: center;
  h6 {
    margin-bottom: 0px;
  }

  .col {
    padding: 0px;
  }
  span {
    display: block;
    min-width: 100%;
    font-size: 2vw;
    font-weight: 750;
    color: $cortt-green !important;
    margin-top: 0px;
    padding-top: 0px;
  }

  small {
    font-size: 95%;
    margin: 0px;
    color: $cortt-green;
  }
}

.deleteQuestion {
  max-width: 30%;
  min-width: 30%;
  position: fixed;
  top: 30vh;
  left: 35vw;
  transition: 0.5s;
  color: white;
  z-index: 5;

  .card {
    background-color: #c7e1f9;
  }

  h5 {
    color: #1c79cd;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  b {
    color: #1c79cd;
    font-weight: 600;
  }

  button {
    float: right;
    padding: 10px 20px;
    margin: 0px 5px;
    background: none;
    border: solid 1px #1c79cd;
    border-radius: 25px;
    color: #1c79cd;
    transition: 0.5s;

    &:hover {
      background-color: white;
      color: $cortt-blue;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  .row {
    margin-top: 5px;
  }
}

//Custom Segment Detail -----------------------------------------------------------------------------------------------------------------------
.customSegmentDetail {
  .row {
    margin-bottom: 10px;
  }

  .backButtonCol {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;

    button {
      margin: 0px 10px;
      position: relative;
      background-color: $button-red;
      float: right;
      border: none;
      transition: 0.5s;

      &:hover {
        background-color: $button-red-h;
        transform: scale(1.1);
      }
    }
  }

  .doughnut {
    height: 95%;

    .card-body {
      height: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
}

.customDetailGenderSummary {
  //font-size: 35px;
  i {
    font-size: 3em !important;
    padding: 10px;
  }

  p > b {
    font-weight: bold;
  }

  span {
    font-size: 18px;
  }

  .M {
    color: $blue;
  }
  .F {
    color: $red;
  }
}

.btnGetBackToSegmentList {
  padding: 10px 3em;
  border-radius: 15px;
  background: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  transition: 1s;

  &:hover {
    background-color: white;
    color: $lf-red-d;
  }
}

.tableRowButtons {
  text-align: center;
  button {
    display: inline;
  }
}

.chartsRow {
  display: flex;
  align-content: space-between;
  align-items: center;
}

.pointChart {
  .card {
    padding: 15px;
  }
}

.favProdByCustomSegment {
  .productTable {
    margin-top: 15px;
    th {
      background-color: $cortt-blue;
      color: white;
    }
  }

  .favProd {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    h4 {
      color: $lf-red;
      margin: 0px 10px;
    }
    p {
      margin: 0px 10px;
      padding: 5px 15px;
      color: white;
      background-color: $lf-red;
      border-radius: 5px;
    }
  }
}

//Responsive
@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .segmentListItem {
    .btnViewDetail {
      display: block !important;
    }
    h4 {
      max-width: 100%;
      display: inline-block;
      margin-right: 15px;
    }
    .sbList {
      li {
        font-size: 1em;
      }
    }
  }
}
