//Animation File

//Fade in animation classes ------------------------------------------------------------------------------------------------------

.fadeIn-enter {
    opacity: 0;
}

.fadeIn-enter-active {
    opacity: 1;
}

.fadeIn-appear {
    opacity: 0;
}

.fadeIn-appear-active {
    opacity: 1;
}

.fadeIn-enter-done {
    opacity: 1;
}

//Animacion para hacer focus en la seleccion de filtros --------------------------------------------------------------------------------------------------------
.darkPanel {
    position: absolute;
    z-index: 1;
    background-color: darken($cortt-blue, 15%);
    opacity: 0.8;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    transition: all 1s;
    opacity: 0;
}

.darkPanelT-enter {
    opacity: 0;
}

.darkPanelT-enter-active {
    opacity: 0.7;
}

.darkPanelT-enter-done {
    opacity: 0.7;
}

//Fade in up animation  -----------------------------------------------------------------------------------------------------

@keyframes fadeInUp {
    from {
        transform: translate3d(0, 10px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 10px, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.animated-enter {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    overflow: hidden;
}

.animated-appear {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    overflow: hidden;
}

.animatedFadeInUp {
    opacity: 0;
}

.animated-enter-active {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    overflow: hidden;
}

.animated-appear-active {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    overflow: hidden;
}

.animated-exit {
    opacity: 1;
}
.animated-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

//Slide from left animation  -----------------------------------------------------------------------------------------------------

@keyframes slideLeft {
    from {
        transform: translateX(60px);
    }

    to {
        transform: translateX(0);
    }
}

@-webkit-keyframes slideLeft {
    from {
        //transform: translate3d(30px,0,0)
        transform: translateX(60px);
    }

    to {
        transform: translateX(0);
    }
}

.slides-enter {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    overflow: hidden;
    overflow-x: hidden;
}

.slides-enter-active {
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;
    overflow: hidden;
    overflow-x: hidden;
}

//Loading spinner classes -----------------------------------------------------------------------------------------------------
.lds-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    padding: 15px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 8px;
    border: 8px solid black;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.lds-ring-w {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}
.lds-ring-w div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 8px;
    border: 8px solid white;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: white transparent transparent transparent;
}
.lds-ring-w div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring-w div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring-w div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring-w {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

//Endless rotation -------------------------------------------------------------------------------------------------------------
@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spin {
    -webkit-animation: spin 15s linear infinite;
    -moz-animation: spin 15s linear infinite;
    animation: spin 15s linear infinite;
}

//DropDowns

.animationDropOpen {
    height: 0px;
    display: none;
    transition: 1s;

    &.active {
        display: block;
        height: auto;
    }
}

//SMS text Messages
@keyframes msg1 {
    0% {
        //   top: calc(var(--msg-content-height) - var(--msg1-height));
        //   left: -4em;
        transform: scale(0);
    }
    100% {
        //   top: calc(var(--msg-content-height) - var(--msg1-height));
        //   left: 0;
        transform: scale(1);
    }
}


//Animation with Exit
.ani-enter {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
}

.ani-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 300ms, transform 500ms;
}

.ani-appear {
    opacity: 0;
}

.ani-appear-active {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    overflow: hidden;
}

.ani-exit {
    opacity: 1;
}

.ani-exit-active {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
    transition: opacity 300ms, transform 300ms;
}