.modalConfigRfm{
    p{
        width: 100%;
        text-align: center;
    }
    .modalContent{
        width: 100%;
        display: flex;
        justify-content: center;

        button{
            margin: 5px;
            background-color: $button-blue;
            color: white;
            transition: .5s;

            &:hover{
                background-color: white;
                color:$button-blue;
            }
        }
    }
}