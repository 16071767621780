//GENERAL CLASSES
.dashboard{
    .mainRow{
        margin-bottom: 15px;
    }
}

// GLOBAL COUNTS
.globalCountsContainer{
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: center;
    height: 100%;

    .singleCard{
        .card{width: 100%;}
    }

    .gbRow{
        width: 100%;
        padding: 0px !important;
    }
}

.singleCard{
    font-family: 'rhw';
    align-self: stretch;
    //margin-bottom: 2em;
    h3{
        color: $cortt-blue;
    }

    &.dataLoading{
        .card{
            padding: 10px 7.5px;
        }
    }

    .card{
        padding: 20px 7.5px;
    }

    .cardValue{
        color:$cortt-green;
        font-weight: 900;
        font-size: 1.6vw;
    }

    .cardTitle{
        font-weight: 900;
        font-size: 1.6vw
    }

    .avgTicketSubdata{
        p{margin-bottom: 0px; font-size: 1vw;}
        b{color:$cortt-green; font-size: 1vw;}
    }

    .explain{
        color: $lf-gray-l;
        font-size: 1vw;
        position: relative;
        bottom: 0px;
    }
}

// @media screen and (min-width: 768px) and (max-width: 768px)
// @media screen and (min-width: 768px) and (max-width: 768px)
// @media screen and (min-width: 768px) and (max-width: 768px)

@media screen and (max-width: 991px){
    .globalCountsContainer{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-content: center;
        margin-bottom: 10vh;

        .gbRow:nth-child(1) {
            width: 50%;
            padding: 0px !important;
        }

        .gbRow:nth-child(2) {
            width: 50%;
            padding: 0px !important;
        }

        .gbRow:nth-child(3) {
            width: 100%;
            margin-top: 15px;
            padding: 0px !important;
        }
    }

    .singleCard{
        .cardValue{
            //color:$lf-red-l;
            font-weight: 900;
            font-size: 3vw;
        }
    
        .cardTitle{
            //color:$lf-red-l;
            font-weight: 900;
            font-size: 2.5vw
        }
    
        .avgTicketSubdata{
            p{margin-bottom: 0px; font-size: 2vw;}
            b{font-size: 2vw;}
        }

        .explain{
            font-size: 1.5vw;
            position: relative;
            bottom: 0px;
        }
    }

    .genderCount:nth-child(1){
        //margin: 5px;
        padding-left: 0px;
        padding-right:5px;
    }
    .genderCount:nth-child(2){
        //margin: 5px;
        padding-left: 5px;
        padding-right:0px;

    }
    
}
@media (max-width: 577px){
    .globalCountsContainer{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-content: center;
        margin-bottom: 10vh;

        .gbRow:nth-child(1) {
            width: 50%;
            padding: 0px !important;
        }

        .gbRow:nth-child(2) {
            width: 50%;
            padding: 0px !important;
        }

        .gbRow:nth-child(3) {
            width: 100%;
            margin-top: 15px;
            padding: 0px !important;
        }
    }

    .singleCard{
        .cardValue{
            //color:$lf-red-l;
            font-weight: 900;
            font-size: 6vw;
        }
    
        .cardTitle{
            //color:$lf-red-l;
            font-weight: 900;
            font-size: 2.5vw
        }
    
        .avgTicketSubdata{
            p{margin-bottom: 0px; font-size: 4vh;}
            b{font-size: 4vh;}
        }

        .explain{
            font-size: 2.5vw;
            position: relative;
            bottom: 0px;
        }
    }

}

//GLOBA LCHARTS
.globalChart{
   padding: 10px;
   //min-height: 325px;

    .stdOutData{
        color:$cortt-green;
    }
}

.globalChartCol{
    margin-bottom: 15px;

    &.f{
        display: flex;
        justify-content: center;
    }
}

//AGE BRACKETS
.ageBrackets{
    .category{
        color: $lf-gray-l;
        margin: 0px;
    }

    .ageTable{
        font-size: 10pt;
    }
}

//GENDER COUNT
.genderCount{
    margin-bottom: 15px;
    h4{font-weight: 700;}
    i{
        position: relative;
        float: right;
        font-size: 4em;
    }
    .footer{
        margin-top: 25px;
        font-size: 0.9em;
    }

    &.male{
        h4{color: $blue;}
        i{color:$blue}
        .footer{b{color:$blue}}
    }

    &.female{
        h4{color: $red;}
        i{color:$red}
        .footer{b{color:$red}}
    }
}


//GENDER PRODS
.genderProductTable{
    .stdOutData{
        color: $cortt-green;
        &.F{ color: $red;}
        &.M{ color: $blue;}
    }

    .favProd{
        margin-bottom: 15px;
        b{color:$lf-light-nickel}
        label{color:$lf-gray-l}
        i{
            position: relative;
            float: left;
            font-size: 3em;
            margin-right: 15px;
            &.fa-male{color: $blue;}
            &.fa-female{color: $red;}
        }
        .F{color:$red; margin-left: 5px;}
        .M{color:$blue; margin-left: 5px;}
    }

    .tableProductdsByGender{
        .F{color:$red}
        .M{color:$blue}
        td{font-size: 0.9em;}
    }
}
