.adminContainer {
    // display: flex;
    padding: 10px;

    .titleRow {
        padding: 10px;
    }

    label {
        color: $lf-nickel;
    }

    .contentRow {
        display: flex;
        padding: 10px;

        .adminSubmenu {
            width: 20%;
            height: 100%;
            // margin: auto;
            // padding: 0px 10px;

            ul {
                list-style: none;
                // background-color: $lf-light-nickel;
                background-color: $cortt-blue;
                height: auto;
                // padding-left: 15%;
                li {
                    cursor: pointer;
                    margin: 10px 0px 10px 0px;
                    padding: 7.5px 7.5% 7.5px 7.5%;
                    transition: 0.5s;
                    // color: $cortt-green;
                    color: white;

                    &:hover {
                        // background-color: $cortt-green;
                        // border-bottom: 5px inset $cortt-green;
                        color: $cortt-green;
                    }

                    &.active {
                        // border-bottom: 5px inset $cortt-green;
                        background-color: $cortt-green;
                        color: white;
                        // background-color: lighten($lf-light-nickel, 6% );
                    }
                }
            }
        }
        .adminScreenContent {
            width: 80%;
            height: auto;
            padding: 25px;
            border: 1px gray solid;

            .campaigns {
                h4 {
                    color: $button-blue;
                }
                h3 {
                    color: $button-blue;
                }
                .titleRow {
                    padding: 0px;
                    button {
                        background-color: $button-blue;
                        &:hover {
                            background-color: $button-blue-h;
                        }
                    }
                }
                .activateRow {
                    display: flex;
                    // justify-content: center;
                    align-content: center;
                    align-items: center;
                    padding-top: 15px;

                    .formGroup {
                        width: 100%;
                        input {
                            margin: 0px 5px;
                        }

                        label {
                            margin: 0px 5px;
                        }
                    }
                }
                .emailsRow {
                    margin-top: 25px;
                    margin-bottom: -10px;
                    padding: 10px 0px;
                    border-top: 1px solid $cortt-blue;
                    // border-bottom: 1px solid $cortt-blue;
                    display: flex;
                    flex-flow: column;

                    .titleRow{
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        padding: 15px;

                        div{
                            display: flex;
                            align-content: center;
                            label{margin-right: 10px;}
                        }
                    }

                    h4 {
                        width: auto;
                    }
                    .formGroup {
                        margin-top: 15px;
                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"] {
                            width: 80%;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        button {
                            background-color: lighten($color: $cortt-green, $amount: 35%);
                            border: none;
                            margin: 0px 5px;
                            transition: 0.5s;
                            &.active {
                                background-color: $cortt-green;
                            }

                            &.disabled {
                                background-color: $lf-light-nickel;
                                &.active {
                                    background-color: $lf-nickel;
                                }
                            }
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }
                        .half {
                            width: 50%;
                            label {
                                min-width: 20%;
                            }

                            input {
                                min-width: 80%;
                            }
                        }

                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }
                }
                .smsRow {
                    margin-top: 25px;
                    margin-bottom: -10px;
                    padding: 10px 0px;
                    border-top: 1px solid $cortt-blue;
                    // border-bottom: 1px solid $cortt-blue;
                    display: flex;
                    flex-flow: column;

                    .titleRow{
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        padding: 15px;

                        div{
                            display: flex;
                            align-content: center;
                            label{margin-right: 10px;}
                        }
                    }

                    .formGroup {
                        margin-top: 15px;
                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"],
                        input[type="password"] {
                            width: 80%;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }
                        .half {
                            width: 50%;
                            label {
                                min-width: 20%;
                            }

                            input {
                                min-width: 80%;
                            }
                        }

                        //Sizes
                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }
                }
                .facebookRow {
                    margin-top: 25px;
                    margin-bottom: -10px;
                    padding: 10px 0px;
                    border-top: 1px solid $cortt-blue;
                    // border-bottom: 1px solid $cortt-blue;
                    display: flex;
                    flex-flow: column;

                    .titleRow{
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        padding: 15px;

                        div{
                            display: flex;
                            align-content: center;
                            label{margin-right: 10px;}
                        }
                    }
                    .formGroup {
                        margin-top: 15px;
                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"],
                        input[type="password"] {
                            width: 80%;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }

                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }
                }
                .cuponeraRow {
                    margin-top: 25px;
                    margin-bottom: -10px;
                    padding: 10px 0px;
                    border-top: 1px solid $cortt-blue;
                    // border-bottom: 1px solid $cortt-blue;
                    display: flex;
                    flex-flow: column;
                    .formGroup {
                        margin-top: 15px;
                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"],
                        input[type="password"] {
                            width: 80%;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }

                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }
                }
            }

            .general {
                .titleRow {
                    padding: 0px;
                    button {
                        background-color: $button-blue;
                        &:hover {
                            background-color: $button-blue-h;
                        }
                    }
                }
                .contentRow {
                    .formGroup {
                        margin-top: 15px;
                        width: 100%;
                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"] {
                            width: 80%;
                            border: 1px solid $lf-light-nickel;
                            border-radius: 5px;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        button {
                            background-color: lighten($color: $cortt-green, $amount: 35%);
                            color: $lf-nickel;
                            border: none;
                            border-radius: 5px;
                            margin: 5px 5px;
                            padding: 5px 15px;
                            transition: 0.5s;
                            &:focus {
                                outline: none;
                            }
                            &.active {
                                background-color: $cortt-green;
                                color: white;
                            }

                            &.disabled {
                                background-color: $lf-light-nickel;
                                &.active {
                                    background-color: $lf-nickel;
                                }
                            }
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }
                        .half {
                            width: 50%;
                            label {
                                min-width: 20%;
                            }

                            input {
                                min-width: 80%;
                            }
                        }

                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }

                    hr {
                        width: 100%;
                    }
                }
            }

            .permisos {
                .titleRow {
                    padding: 0px;
                    button {
                        background-color: $button-blue;
                        &:hover {
                            background-color: $button-blue-h;
                        }
                    }
                }
                .subtitle {
                    width: 100%;
                    .sub {
                        width: 100%;
                        margin-bottom: 0px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        b {
                            width: auto;
                        }

                        button {
                            color: white;
                            background-color: $button-blue;
                            transition: 0.5s;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                    .buttons {
                        display: flex;
                        button {
                            margin: 0px 5px;

                            &.cancelar {
                                background-color: $button-red;
                            }
                        }
                    }
                }
                .createRow {
                    // margin-top: 20px;

                    padding: 15px;
                    &.active {
                        background-color: #454d55;
                        border: solid 1px black;
                        color: white;
                        margin: 20px -4px 0px -4px;
                        .formGroup {
                            label {
                                color: white;
                            }
                        }
                    }

                    .formGroup {
                        // margin-top: 15px;
                        // border: solid 1px black;
                        width: 100%;

                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"] {
                            width: 80%;
                            border: 1px solid $lf-light-nickel;
                            border-radius: 5px;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        button {
                            background-color: lighten($color: $cortt-green, $amount: 35%);
                            color: $lf-nickel;
                            border: none;
                            border-radius: 5px;
                            margin: 5px 5px;
                            padding: 5px 15px;
                            transition: 0.5s;
                            &:focus {
                                outline: none;
                            }
                            &.active {
                                background-color: $cortt-green;
                                color: white;
                            }

                            &.disabled {
                                background-color: $lf-light-nickel;
                                &.active {
                                    background-color: $lf-nickel;
                                }
                            }
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }

                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }
                }
                .contentRow {
                    .formGroup {
                        margin-top: 15px;
                        width: 100%;

                        b {
                            width: 100%;
                        }
                        label {
                            width: 20%;
                        }
                        input[type="text"] {
                            width: 80%;
                            border: 1px solid $lf-light-nickel;
                            border-radius: 5px;
                        }
                        input[type="checkbox"] {
                            margin-right: 10px;
                        }
                        table {
                            button {
                                color: white;
                                background-color: #1470c7ff;
                                transition: 0.5s;

                                &.cancelar {
                                    background-color: $button-red;
                                }
                            }
                        }
                        button {
                            background-color: lighten($color: $cortt-green, $amount: 35%);
                            color: $lf-nickel;
                            border: none;
                            border-radius: 5px;
                            margin: 5px 5px;
                            padding: 5px 15px;
                            transition: 0.5s;
                            &:focus {
                                outline: none;
                            }
                            &.active {
                                background-color: $cortt-green;
                                color: white;
                            }

                            &.disabled {
                                background-color: $lf-light-nickel;
                                &.active {
                                    background-color: $lf-nickel;
                                }
                            }
                        }
                        .tip {
                            border-radius: 10px;
                            margin-right: 10px;
                            padding: 1px 6px;
                            background-color: $lf-red;
                            font-size: 8pt;
                            color: white;
                        }
                        .half {
                            width: 50%;
                            label {
                                min-width: 20%;
                            }

                            input {
                                min-width: 80%;
                            }
                        }

                        .w10 {
                            width: 10% !important;
                        }
                        .w20 {
                            width: 20% !important;
                        }
                        .w30 {
                            width: 30% !important;
                        }
                        .w40 {
                            width: 40% !important;
                        }
                        .w50 {
                            width: 50% !important;
                        }
                        .w60 {
                            width: 60% !important;
                        }
                        .w70 {
                            width: 70% !important;
                        }
                        .w80 {
                            width: 80% !important;
                        }
                        .w90 {
                            width: 90% !important;
                        }
                        .w100 {
                            width: 100% !important;
                        }
                    }

                    hr {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.permisosConfirmationModal {
    display: flex;
    justify-content: center;
    text-align: center;
}

.editAdminModal {
    min-width: 60%;

    .modal-content > .modal-body > .content {
        .row {
            .emailRow {
                width: 100%;
                // padding: 0px 5px;
                label {
                    width: 10%;
                }
                input[type="text"] {
                    width: 90%;
                }
            }

            &.buttonRow{
                padding-top: 15px;
                display: flex;
                justify-content: center;
                button{
                    background-color: $button-blue;
                }
            }

            .col-sm-12.col-md-4.col-lg-4 {
                padding: 0px 10px;
                .permissionCard {
                    width: 100%;
                    // min-height: 300px;
                    border: 1px solid gray;
                    border-radius: 5px;
                    padding: 10px 2px 5px;
                    text-align: center;
                    .bucketCard {
                        padding: 2px;
                        margin-top: 15px;
                        text-align: center;
                        ul {
                            padding: 2px;
                            list-style: none;
                            // border: 1px solid gray;
                            // border-radius: 5px;
                            width: 100%;
                            max-height: 300px;
                            text-align: center;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                width: 10px;
                            }
                            &::-webkit-scrollbar-track {
                                background: #f1f1f1;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: #888;
                            }
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }

                            li {
                                border-bottom: solid 1px $lf-gray;
                                padding: 7.5px 0px;
                                button {
                                    width: 100%;
                                    margin: 0px;
                                    background-color: $lf-gray;
                                    color: white;
                                }

                                &.item{
                                    span{
                                        cursor: pointer;
                                        color: $button-blue;
                                        transition: .5s;
                                        padding: 2px;
                                        border-radius: 2px;
                                        margin-left: auto;
                                        float: right;

                                        &:hover{
                                            background-color: $button-blue;
                                            color: white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        
    }
}

@media screen and (max-width: 768px) {
    .adminContainer {
        .contentRow {
            .adminSubmenu {
                width: 100%;
                ul {
                    display: flex;
                    flex-flow: row wrap !important;
                    justify-content: space-between;
                    padding: 0px;
                    li {
                        padding: 10px 15px;
                        margin: 0px;
                        &:hover {
                            // background-color: $cortt-green;
                            border-bottom: 5px inset $cortt-green;
                            color: $cortt-green;
                        }

                        &.active {
                            // border-bottom: 5px inset $cortt-green;
                            background-color: $cortt-green;
                            color: white;
                            // background-color: lighten($lf-light-nickel, 6% );
                        }
                    }
                }
            }
            .adminScreenContent {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .adminContainer {
        .contentRow {
            .adminSubmenu {
                width: 100%;
                ul {
                    display: flex;
                    flex-flow: row wrap !important;
                    justify-content: space-between;
                    padding: 0px;
                    li {
                        padding: 10px 15px;
                        margin: 0px;
                        font-size: 0.8rem;

                        &:hover {
                            // background-color: $cortt-green;
                            border-bottom: 5px inset $cortt-green;
                            color: $cortt-green;
                        }

                        &.active {
                            // border-bottom: 5px inset $cortt-green;
                            background-color: $cortt-green;
                            color: white;
                            // background-color: lighten($lf-light-nickel, 6% );
                        }
                    }
                }
            }
            .adminScreenContent {
                width: 100%;
            }
        }
    }

    .editAdminModal {
        min-width: 60%;
    }
}
