.titleRow {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	button {
		background-color: $button-red;
		color: white;
		border: none;
		transition: 0.5s;

		&:hover {
			background-color: $button-red-h;
			transform: scale(1.1);
		}
	}
}

.nameAndButton {
	display: flex;
	flex-flow: wrap row;
	justify-content: space-between;
	align-items: center;
}
.innerSection {
	padding: 1 5px 15px;
	margin: 10px 0px;
	display: flex;
	flex-flow: wrap column;
	.card {
		padding: 15px;
	}
	.firstRow {
		flex: 0 0 100%;
		display: flex;
		flex-flow: wrap row;
		justify-content: space-between;
		div {
			flex: 0 0 49%;
			justify-content: center;
		}
	}
	.statsCard {
		display: flex;
		flex-flow: wrap row;
		padding: 25px 0px;
		align-items: center;
		.mainHalf {
			flex: 0 0 100%;
			display: flex;
			flex-flow: wrap row;
			justify-content: center;
			align-items: center;
			align-content: flex-start;
			h2 {
				margin: 0px 10px 0px 0px;
				color: #dd0337;
			}
			h4 {
				margin: 0;
			}
		}
		.secondHalf {
			flex: 0 0 100%;
			display: flex;
			flex-flow: wrap row;
			align-content: space-evenly;
			div {
				flex: 0 0 100%;
				display: flex;
				flex-flow: wrap row;
				justify-content: center;
				margin: 5px 0px;
				b {
					margin-right: 10px;
					align-self: center;
				}
				p {
					margin: 0px;
				}
				&.segments {
					flex: 0 0 100%;
					display: flex;
					flex-flow: wrap row;
					margin-top: 5px;
					div {
						display: flex;
						flex: 0 0 20%;
						padding: 2px;
						background-color: #dd0337;
						border-radius: 5px;
						text-align: center;
						justify-content: center;
						span {
							color: white;
						}
					}
				}
			}
		}
	}
	.croChart {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 25px 15px;
		.chartTitle {
			display: flex;
			justify-content: center;
		}
	}
	.rateBar {
		display: flex;
		flex-flow: wrap row;
		justify-content: center;
		padding: 15px;
		max-height: 180px;
		.barInfo {
			flex: 0 0 100%;
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			align-items: center;
			.barPercentage {
				font-size: 2em;
			}
		}
		div {
			flex: 0 0 100%;
		}
	}
	.mainStats {
		width: 100%;
		display: flex;
		flex-flow: wrap column;
		justify-content: center;
		align-items: stretch;
		margin-bottom: 25px;
		max-height: 180px;
		.statsTable {
			flex: 0 0 100%;
			display: flex;
			flex-flow: wrap row;
			div {
				flex: 0 0 33%;
				display: flex;
				flex-flow: wrap column;
				align-items: center;
				justify-content: space-between;
				padding: 15px;
				b {
					color: #dd0337;
				}
				.bPerc {
					font-size: 2em;
				}
				span {
					background-color: #dd0337;
					border-radius: 5px;
					padding: 5px 15px;
					b {
						color: white !important;
					}
				}
			}
			.entregados {
				b {
					color: #808d93;
				}
				span {
					background-color: #808d93;
				}
			}
			.rebotados {
				b {
					color: #a2052a;
				}
				span {
					background-color: #a2052a;
				}
			}
			.abiertos {
				b {
					color: #2e3d44;
				}
				span {
					background-color: #2e3d44;
				}
			}
		}
	}
	.detailStats {
		width: 100%;
		align-self: center;
		.r {
			border-bottom: solid 1px #555;
		}
		div {
			padding: 10px;
			display: flex;
			flex-flow: wrap row;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			p {
				margin: 0px;
			}
			i {
				background-color: #808d93;
				color: white;
				border-radius: 15px;
				margin-left: 10px;
				padding: 2px 4px;
				font-size: 8px;
			}
		}
		.pink {
			small {
				margin: 0px 10px;
				color: $lf-red;
			}
		}
		.tooltip {
			max-width: 50%;
		}
	}
	.loading {
		flex: 0 0 100%;
		display: flex;
		flex-flow: wrap column;
		justify-content: center;
		align-content: center;
		text-align: center;
		i {
			text-align: center;
			-webkit-animation: spin 35s linear infinite;
			-moz-animation: spin 35s linear infinite;
			animation: spin 35s linear infinite;
			&::before {
				color: #dd0337;
				font-size: 16em;
			}
		}
	}
	.searchTextRow {
		display: flex;
		padding: 15px;
		input {
			max-width: 95%;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}
		button {
			min-width: 5%;
			background: none;
			border: solid $lf-light-nickel 1px;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	}
	.tableClients {
		thead {
			background-color: $cortt-blue;
			color: white;
		}
		.btnClientDetail {
			border: none;
			background-color: $button-blue;
			color: white;
			padding: 5px 10px;
			border-radius: 3px;
			i {
				margin: 0px 5px;
			}
		}
	}
}


//SMS
.smsCampaignDetail {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin: 35px 0px 35px 0px ;

	.stats {
		width: 100%;
		.statsRow {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;

			.content{
				text-align: center;
				margin-bottom: 35px;
			}

			.dates{
				display: flex;
				justify-content: center;
				
			}

			h4,h6{
				color: $lf-red;
				margin-right: 10px;
			}

			.buttons{
				display: flex;
				width: 100%;
				justify-content: center;
				margin-top: 15px;
				button{
					margin: 0px 10px 0px 10px;
					border: none;
					background-color: $button-blue;
					transition: .5s;
					&:hover{
						background-color: $button-blue-h;
						transform: scale(1.1);
					}
				}

				.loading{
					p{display: none;}
				}
			}
		}
	}
}

.campaignDatePickerD {
    position: absolute;
    right: 39%;
    top: 10%;
    z-index: 2;
    
    .card{
        background-color: $button-blue;
        color: white;
        padding: 25px;
        .card-body {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
    
            p{
                text-align: center;
                margin: 10px 5px;
            }

            .btnCreate{
                background-color: lighten($color: $button-blue, $amount: 25%);
                border: none;
                color: white;  
				transition: .5s;
				margin-top: 10px;
                &:hover{
                    background-color:white;
                    color: lighten($color: $button-blue, $amount: 25%);
                }
            }
        }
    }
    
}
