.login{
    // padding-top: 10em;
}

.btnIngresar{
    background-color: $cortt-green;
    padding: 5px 50px;
    margin-top: 30px;
}

.App-header {
    min-height: 100vh;
    text-align: center;
    font-size: calc(10px + 1vmin);
}

.addMarginB{
    margin-bottom: 2rem;
  }
  
.cInput{
    border-color: white;
    border-radius: 0.25em;
    border-width: 0.5px;
    background-color: transparent !important;
    color: white;
    padding: 7px;

}
 
.inputError{
    padding: 5px 15px;
    font-size: 13px;
    background: rgb(234, 234, 240);
    display: inline-block;
    color: red;
    border-radius: 10px;
}

.loginLabel{
    text-align: left;
    font-size: 1.2em;
}

.loginFooter{
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    font-size: 12pt;

    a{
        color: $cortt-green;
    }
}