.campaignCreator {
    &.blur {
        filter: blur(2px);
    }

    .titleRow {
        justify-content: space-between;
        align-items: baseline;
        p {
            color: $lf-red-l;
        }

        div {
            display: flex;
            justify-content: flex-end;
            align-items: center !important;
            align-content: center;
            p {
                margin: 0px;
            }
            button {
                margin: 0px 15px 0px 2.5px;
            }
        }
    }

    .campaignTypeSelectionForm {
        .buttonsRow {
            display: flex;
            justify-content: center;
            margin: 50px 0px;

            button {
                margin: 0px 10px;
                text-transform: uppercase;
                padding: 10px 50px;
                background-color: $button-blue;
                border: none;
                font-size: 0.9em;
                transition: 0.5s;

                &:hover {
                    background-color: $button-blue-h;
                    transform: scale(1.1);
                }
            }
        }
    }

    .emailCampaignCreatorForm {
        .row {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        .stepRow {
            margin: 30px -10px;
            display: flex;
            justify-content: center;

            .breadcrumps {
                .col {
                    display: flex;
                    justify-content: center;

                    span {
                        margin: 0px 5px;
                        cursor: pointer;
                        transition: 0.5s;
                        color: $lf-gray;
                        // text-decoration: underline dotted $button-blue;

                        &:hover {
                            color: $button-blue;
                        }

                        &.middle {
                            text-decoration: none;
                        }

                        &.active {
                            color: $lf-red;
                            // text-decoration: underline dotted $lf-red;
                            &:hover {
                                color: $button-blue;
                                // text-decoration: underline dotted $button-blue;
                            }
                        }
                    }
                }
            }
        }

        .formGroup {
            margin: 20px 0px;

            label,
            input,
            p {
                margin: 5px 0px;
            }

            p {
                color: $lf-nickel;
                font-size: 0.8em;
            }

            b {
                color: $lf-red;
            }

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $lf-light-nickel;
                opacity: 0.8; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $lf-light-nickel;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $lf-light-nickel;
            }

            .requiredLabel {
                font-size: 10px;
                background-color: $lf-red;
                border: solid $lf-red-l 1px;
                border-radius: 30px;
                padding: 0.5px 6px;
                margin-left: 5px;
                transition: 0.5s;
                color: white;

                &:hover {
                    background-color: $lf-red;
                    color: white;
                }
            }
        }

        .emailTypeSelectCard {
            text-align: center;

            .card {
                min-height: 50vh;
                transition: 0.5s;
                cursor: pointer;

                &:hover {
                    border: 1.5px solid $button-blue;
                }
            }

            .card-body {
                display: flex;
                flex-flow: column wrap;
                justify-content: space-evenly;
                align-content: center;
                align-items: center;
            }

            .img-responsive {
                width: 40%;
                height: auto;
                margin: 15px;
            }

            span {
                width: 40%;
                height: auto;
            }

            p {
                color: $lf-nickel;
            }
        }

        .emailEditor {
            width: 100%;
            height: 100%;
            background-color: white;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 3;

            .buttonContainer {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                background-color: $cortt-blue;

                h5 {
                    margin-left: 15px;
                    margin-right: auto;
                    color: white;
                }

                button {
                    background-color: $button-blue;
                    color: white;
                    border: none;
                    margin: 10px;
                    transition: 0.5s;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    align-content: center;

                    // span {
                    //     margin-right: 8px;
                    // }

                    &:hover {
                        background-color: lighten($color: $button-blue-h, $amount: 25%);
                        color: $cortt-blue;
                    }
                }

                .mainButton {
                    margin: 10px;
                    button {
                        margin: 0px;
                    }
                }

                .dropdown-menu {
                    background-color: $button-blue;
                }
                .logo {
                    width: 40px;
                    height: 40px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }

        .cuponSelector {
            .buttonContainer {
                display: flex;
                justify-content: center;
                margin-top: 45px;
                button {
                    margin: 0px 10px;
                    text-transform: uppercase;
                    padding: 10px 50px;
                    background-color: $button-blue;
                    border: none;
                    font-size: 0.9em;
                    transition: 0.5s;

                    &:hover {
                        background-color: $button-blue-h;
                        transform: scale(1.1);
                    }

                    span {
                        margin: 0px 5px;
                    }
                }
            }
        }

        .imageUploader {
            .buttonContainer {
                display: flex;
                justify-content: center;
                margin-top: 45px;
                button {
                    margin: 0px 10px;
                    text-transform: uppercase;
                    padding: 10px 50px;
                    background-color: $button-blue;
                    border: none;
                    font-size: 0.9em;
                    transition: 0.5s;

                    &:hover {
                        background-color: $button-blue-h;
                        transform: scale(1.1);
                    }

                    span {
                        margin: 0px 5px;
                    }
                }
            }
        }
    }

    .smsCampaignCreatorForm {
        .row {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        .stepRow {
            margin: 30px -10px;
            display: flex;
            justify-content: center;

            .breadcrumps {
                .col {
                    display: flex;
                    justify-content: center;

                    span {
                        margin: 0px 5px;
                        cursor: pointer;
                        transition: 0.5s;
                        color: $lf-gray;
                        // text-decoration: underline dotted $button-blue;

                        &:hover {
                            color: $button-blue;
                        }

                        &.middle {
                            text-decoration: none;
                        }

                        &.active {
                            color: $lf-red;
                            // text-decoration: underline dotted $lf-red;
                            &:hover {
                                color: $button-blue;
                                // text-decoration: underline dotted $button-blue;
                            }
                        }
                    }
                }
            }
        }

        .formGroup {
            margin: 20px 0px;

            label,
            input,
            p {
                margin: 5px 0px;
            }

            p {
                color: $lf-nickel;
                font-size: 0.8em;
            }

            b {
                color: $lf-red;
            }

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $lf-light-nickel;
                opacity: 0.8; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $lf-light-nickel;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $lf-light-nickel;
            }

            .requiredLabel {
                font-size: 10px;
                background-color: $lf-red;
                border: solid $lf-red-l 1px;
                border-radius: 30px;
                padding: 0.5px 6px;
                margin-left: 5px;
                transition: 0.5s;
                color: white;

                &:hover {
                    background-color: $lf-red;
                    color: white;
                }
            }

            .alert {
                color: $lf-red;
            }

            .availableCharacters {
                margin-top: 10px;
                color: $button-blue;
            }
        }

        .smsTypeSelectCard {
            text-align: center;

            .card {
                min-height: 50vh;
                transition: 0.5s;
                cursor: pointer;

                &:hover {
                    border: 1.5px solid $button-blue;
                }

                &.active{
                    border: 1.5px solid $button-blue;
                }
            }

            .card-body {
                display: flex;
                flex-flow: column wrap;
                justify-content: space-evenly;
                align-content: center;
                align-items: center;
            }

            .img-responsive {
                width: 40%;
                height: auto;
                margin: 15px;
            }

            span {
                width: 40%;
                height: auto;
            }

            p {
                color: $lf-nickel;
            }
        }

        .stepMessage {
            margin-top: 5%;
            width: 100%;
            height: auto;
            text-align: center;

            p {
                color: $lf-nickel;
                opacity: 0.6;
            }
        }

        .inputGroup {
            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $lf-light-nickel;
                opacity: 0.8; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $lf-light-nickel;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $lf-light-nickel;
            }
        }
    }

    .facebookCampaignCreatorForm {
        .row {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        .stepRow {
            margin: 30px -10px;
            display: flex;
            justify-content: center;

            .breadcrumps {
                .col {
                    display: flex;
                    justify-content: center;

                    span {
                        margin: 0px 5px;
                        cursor: pointer;
                        transition: 0.5s;
                        color: $lf-gray;
                        // text-decoration: underline dotted $button-blue;

                        &:hover {
                            color: $button-blue;
                        }

                        &.middle {
                            text-decoration: none;
                        }

                        &.active {
                            color: $lf-red;
                            // text-decoration: underline dotted $lf-red;
                            &:hover {
                                color: $button-blue;
                                // text-decoration: underline dotted $button-blue;
                            }
                        }
                    }
                }
            }
        }

        .formGroup {
            margin: 20px 0px;

            label,
            input,
            p {
                margin: 5px 0px;
            }

            p {
                color: $lf-nickel;
                font-size: 0.8em;
            }

            b {
                color: $lf-red;
            }

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $lf-light-nickel;
                opacity: 0.8; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $lf-light-nickel;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $lf-light-nickel;
            }

            .requiredLabel {
                font-size: 10px;
                background-color: $lf-red;
                border: solid $lf-red-l 1px;
                border-radius: 30px;
                padding: 0.5px 6px;
                margin-left: 5px;
                transition: 0.5s;
                color: white;

                &:hover {
                    background-color: $lf-red;
                    color: white;
                }
            }
        }

        .facebookContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            border: solid 1px $lf-gray;
            border-radius: 2px;
            margin-top: 10vh;
            padding: 15px;

            .facebookExplanation {
                display: flex;
                height: 30vh;
                flex-flow: wrap column;
                justify-content: space-evenly;
                align-content: space-between;
                align-items: stretch;
                p {
                    text-align: center;
                    color: $lf-gray-l;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .facebookLoginInfo {
            margin-top: 5vh;
            h5 {
                width: 100%;
                text-align: center;
            }
        }
    }

    .segmentSelectorForm {
        .segmentRow {
            display: flex;
            align-items: center;
        }

        .segmentCard {
            // .card {
            //     height: 50vh;
            // }
            .container {
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                justify-content: space-between;

                div,
                b,
                p {
                    margin: 10px 0px;
                }

                .icon {
                    display: flex;
                    align-items: baseline;
                }

                b {
                    color: $lf-red;
                }

                p {
                    width: 70%;
                    color: $lf-light-nickel;
                    text-align: center;
                    font-size: 0.9em;
                }

                .selector {
                    cursor: pointer;

                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        padding: 2px;
                        margin: 0px;
                        width: 25px;
                        height: 25px;
                        border-radius: 15px;
                        background-color: white;
                        border: solid $lf-gray-l 2px;
                        transition: 0.5s;

                        .ball {
                            display: block;
                            width: 80%;
                            height: 80%;
                            background-color: $lf-red;
                            border-radius: 15px;
                            margin: 0px;
                            opacity: 0;
                            transition: 0.5s;
                        }

                        &:hover {
                            border-color: $lf-red;
                        }

                        &.active {
                            border-color: $lf-red;
                            .ball {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .peopleCounter {
            width: 100%;
            margin: 15px 0px;
            display: flex;
            justify-content: flex-end;

            span {
                margin: 0px 2px;
            }

            .customSegmentName {
                margin-right: 15px;
                color: $lf-red;
            }

            .count {
                color: $lf-red;
            }
        }

        input {
            //Search Bar
            border-color: $lf-red;
            margin-bottom: 5px;
        }

        .customSegmentTable {
            tr {
                transition: 0.5s;
                .selectButton {
                    background-color: $lf-light-nickel;
                    border: none;
                    transition: 0.5s;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
                &.active {
                    background-color: $lf-red;
                    color: white;
                    .selectButton {
                        background-color: white;
                        color: $lf-red;
                    }
                }
            }
        }

        .buttonsRow {
            button {
                background-color: $button-blue;
                color: white;
                border: none;
                margin: 10px;
                transition: 0.5s;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                align-content: center;

                span {
                    margin-right: 8px;
                }

                &:hover {
                    background-color: lighten($color: $button-blue-h, $amount: 25%);
                    color: $cortt-blue;
                }
            }
        }
    }

    .selectCard {
        .card {
            min-height: 50vh;
            margin-bottom: 10px;
        }
        .container {
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: space-between;

            div,
            b,
            p {
                margin: 10px 0px;
            }

            .icon {
                display: flex;
                align-items: baseline;
            }

            b {
                color: $lf-red;
            }

            p {
                width: 70%;
                color: $lf-light-nickel;
                text-align: center;
                font-size: 0.9em;
            }

            .selector {
                cursor: pointer;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    padding: 2px;
                    margin: 0px;
                    width: 25px;
                    height: 25px;
                    border-radius: 15px;
                    background-color: white;
                    border: solid $lf-gray-l 2px;
                    transition: 0.5s;

                    .ball {
                        display: block;
                        width: 80%;
                        height: 80%;
                        background-color: $lf-red;
                        border-radius: 15px;
                        margin: 0px;
                        opacity: 0;
                        transition: 0.5s;
                    }

                    &:hover {
                        border-color: $lf-red;
                    }

                    &.active {
                        border-color: $lf-red;
                        .ball {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .buttonsRow {
        display: flex;
        justify-content: center;
        margin: 50px 0px;

        button {
            margin: 0px 10px;
            text-transform: uppercase;
            padding: 10px 50px;
            background-color: $button-blue;
            border: none;
            font-size: 0.9em;
            transition: 0.5s;

            &:hover {
                background-color: $button-blue-h;
                transform: scale(1.1);
            }
        }
    }

    .campaignCreationLoading {
        width: 100%;

        p {
            text-align: center;
            margin-top: 25px;
        }
    }

    .finalSelectionForm {
        margin-bottom: 15px;
        .flexCenter {
            width: 100%;
            display: flex;
            justify-content: center;

            .card {
                padding: 15px;
            }

            td {
                width: 50%;
            }

            ul {
                list-style: none;
                padding: 0px;
                li {
                    background-color: $lf-red;
                    color: white;
                    padding: 2.5px;
                    border-radius: 15px;
                    text-align: center;
                    margin: 10px 0px;
                }
            }

            .table-header {
                color: white;
                background-color: $cortt-blue;
                padding: 5px;
                text-align: center;
                padding: 10px;
                margin: -15px -15px 15px -15px;
            }

            .campaignIcon {
                filter: invert(9%) sepia(95%) saturate(5653%) hue-rotate(338deg) brightness(85%) contrast(102%);
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }

            .htmlPreview {
                resize: both;
                // transform: scale(.5) translate(0,0);

                body {
                    transform: scale(0.5);
                }
            }

            .data {
                color: $lf-red;
            }

            .iTooltip {
                i {
                    background-color: #62686b;
                    color: white;
                    border-radius: 10px;
                    margin-left: 10px;
                    padding: 5px 5px;
                    font-size: 8px;
                }
            }
        }

        .loadingText {
            width: 100%;
            text-align: center;
        }
    }

    .selectCardIcon {
        filter: invert(9%) sepia(95%) saturate(5653%) hue-rotate(338deg) brightness(85%) contrast(102%);
    }
}

.upload {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    background-color: white;
    padding: 20px 10px;

    input {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    label {
        cursor: pointer;
        color: $button-blue;
        display: flex;
        flex-flow: column wrap;
        align-items: center;

        h5 {
            font-size: 1em;
        }

        p {
            color: $lf-light-nickel;
        }
    }

    img {
        max-width: 100%;
    }
}

.campaignDatePickerC {
    position: absolute;
    right: 39%;
    top: 40%;
    z-index: 2;

    .card {
        background-color: $button-blue;
        color: white;
        padding: 25px;
        .card-body {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;

            p {
                text-align: center;
                margin: 10px 5px;
            }

            .btnCreate {
                background-color: lighten($color: $button-blue, $amount: 25%);
                border: none;
                color: white;
                transition: 0.5s;
                margin-top: 10px;
                &:hover {
                    background-color: white;
                    color: lighten($color: $button-blue, $amount: 25%);
                }
            }
        }
    }
}

.xButton {
    color: lighten($color: $button-blue, $amount: 35%);
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    transition: all 0.8s;
    position: absolute;
    top: 0px;
    right: 10px;

    &:hover {
        color: white;
    }
}

.cuponListTable {
    tr {
        transition: 0.5s;
    }

    .active {
        background-color: $button-blue-h;
        color: white;
    }

    .cuponImg {
        width: 100%;
        height: auto;
    }
}

.cuponDropdown{
    width: 100%;

    h4{
        text-align: center;
    }

    select{
        width: 100%;
        text-align: center;
    }
}

.sendTestEmail {
    label {
        width: 100%;
        font-size: 10px;
        color: $lf-gray;
    }
    span {
        color: $button-blue;
        margin-right: 25px;
    }
    .inputGroup {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        input {
            width: 100%;
        }
        button {
            font-size: 10pt;
            padding: 0px 10px;
            border: solid 1px $button-blue;
            background-color: transparent;
            color: $button-blue;
            height: 36px;
            border-radius: 2px;
            transition: 0.5s;
            margin: 30px 0px 30px;

            &:hover {
                background-color: lighten($color: $button-blue-h, $amount: 35%);
                // color: lighten($color: $button-blue-h, $amount: 40%);
            }
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $lf-light-nickel;
            opacity: 0.8; /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $lf-light-nickel;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $lf-light-nickel;
        }
    }
}

.datePicker {
    display: flex;
    flex-flow: column wrap;
    align-content: center;

    p {
        text-align: center;
        margin: 10px 5px;
    }

    .btnCreate {
        background-color: lighten($color: $button-blue, $amount: 25%);
        border: none;
        color: white;
        transition: 0.5s;
        margin-top: 10px;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.emailModal {
    .confirmation {
        text-align: center;

        button {
            margin-top: 10px;
            background-color: $button-blue;
            border: none;
            margin-left: 5px;
            margin-right: 5px;

            &:hover {
                background-color: $button-blue-h;
            }
        }
    }
}

.smsModal {
    .confirmation {
        text-align: center;

        button {
            margin-top: 10px;
            background-color: $button-blue;
            border: none;
            margin-left: 5px;
            margin-right: 5px;

            &:hover {
                background-color: $button-blue-h;
            }
        }
    }

    .datePicker {
        text-align: center;

        button {
            margin-top: 10px;
            background-color: $button-blue;
            border: none;
            margin-left: 5px;
            margin-right: 5px;

            &:hover {
                background-color: $button-blue-h;
            }
        }
    }
}

.phone {
    border: 40px solid #121212;
    border-width: 55px 7px;
    border-radius: 40px;
    width: 300px;
    height: 500px;
    margin: 50px auto;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
    background-color: white;

    .textMessage {
        // position: absolute;
        // animation-iteration-count: 1;
        // animation-duration: 8s;
        display: flex;
        flex-flow: row wrap;
        font-size: 11pt;
        padding: 10px;
        background-color: #2d67ff;
        color: #ffffff;
        width: 70%;
        min-height: 10%;
        height: auto;
        margin: 15% 0% 0% 6%;
        border-radius: 10px;
        animation-name: msg1;
        // animation-fill-mode: forwards;
    }
}
