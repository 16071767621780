.navbar {
    padding: 10px 30px 10px 15px;
    width: 100%;
    z-index: 1050;
    //background: $navbar-color;

    .navbar-wrapper {
        display: flex;
        align-items: center;
    }

    .navbar-text {
        color: white;
    }

    .btn {
        margin: 0 5px 0 10px;
    }

    &.navbar-absolute {
        position: absolute;
        z-index: 0;
    }

    &.navbar-transparent {
        background: transparent !important;
    }

    &.navbar-white {
        background: white !important;
    }

    .navbar-toggle button:focus,
    .navbar-toggler {
        outline: none;
    }

    b {
        font-size: 12pt;
    }
}

.navCollapse{
    width: 100%;
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-end;
    align-items: center;
}

.navbar-minimize-fixed {
    position: fixed;
    margin-left: 40px;
    margin-top: 14px;
    transition: 0.3s ease;
    color: white;
    z-index: 20;
    opacity: 0;
    transition: 0.2s ease;
    button {
        i {
            font-size: 20px;
        }
    }
}

.navbar-nav li {
    padding: 0 10px;
    a {
        color: white;
    }
    i {
        vertical-align: middle;
        font-size: 20px;
    }
}

.bucketIcon{
    width: 15px;
    height: auto;
}


@media screen and (min-width: 992px) {
    .navbar-collapse {
        background: none !important;
    }

    .navbar .navbar-toggle {
        display: none;
    }

    .navbar {
        .caret {
            position: absolute;
            left: 80%;
            top: 62%;
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 991px) {
    .navbar {
        .container-fluid {
            padding-right: 15px;
            padding-left: 15px;
        }

        .navbar-collapse {
            .input-group {
                margin: 0;
                margin-top: 5px;
            }
        }

        .navbar-nav {
            .btn {
                margin-left: -3px;
                display: flex;

                i {
                    margin-right: 12px;
                }

                span {
                    margin: 0;
                    text-transform: uppercase;
                    font-weight: 300;

                    &,
                    &:hover,
                    &:focus,
                    &:active,
                    &:active:focus {
                        color: black !important;
                    }
                }
            }

            a.nav-link {
                i {
                    opacity: 1;
                }
                p {
                    display: inline-block;
                    text-transform: uppercase;
                }
            }

            .modal-search .modal-dialog {
                padding: 0 40px;
            }
            .dropdown {
                margin: 5px 0;
                .nav-link {
                    padding-bottom: 0;
                }
                .dropdown-menu {
                    .dropdown-item {
                        margin-top: 0;
                        padding-left: 24px;
                    }
                }
            }
        }

        .dropdown.show .dropdown-menu {
            display: block;
        }

        .dropdown .dropdown-menu {
            display: none;
            li a {
                color: black;
            }
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu {
            background-color: transparent;
            border: 0;
            transition: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            width: auto;
            margin: 0px 1rem;
            margin-top: 0px;

            &:before {
                display: none;
            }
        }

        .dropdown-menu .dropdown-item:focus,
        .dropdown-menu .dropdown-item:hover {
            color: red;
        }

        &.bg-white .dropdown-menu .dropdown-item:focus,
        &.bg-white .dropdown-menu .dropdown-item:hover {
            color: red;
        }

        button.navbar-toggler[data-target="#navigation"] {
            padding-top: 0;
        }

        .navbar-toggler-bar {
            display: block;
            position: relative;
            width: 22px;
            height: 1px;
            border-radius: 1px;
            background: red;

            &.navbar-kebab {
                height: 4px;
                width: 4px;
                margin-bottom: 3px;
                border-radius: 50%;
            }

            & + .navbar-toggler-bar {
                margin-top: 7px;
            }

            & + .navbar-toggler-bar.navbar-kebab {
                margin-top: 0px;
            }

            &.bar2 {
                width: 17px;
                transition: width 0.2s linear;
            }
        }

        &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
            background-color: red;
        }

        & .toggled .navbar-toggler-bar {
            width: 24px;

            & + .navbar-toggler-bar {
                margin-top: 5px;
            }
        }

        .navbar-brand {
            margin-left: 20px;
            position: relative;
        }
    }

    .navbar-nav {
        .nav-link {
            i.fa,
            i.tim-icons {
                opacity: 0.8;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .navCollapse{
        //width: 100%;
        //display: flex;
        //flex-flow: wrap row;
        justify-content: space-evenly;
        align-content: space-between;
        border-top: solid $lf-gray-l 1px;
        padding-top: 10px;
    }

    .navbarCol{
        margin-top: 10px;
    }
}

@media screen and (max-width: 576px) {
    .navbar[class*="navbar-toggleable-"] .container {
        margin-left: 0;
        margin-right: 0;
    }
    .navbar .navbar-wrapper {
        .navbar-toggle {
            transition: all 1s;
        }
    }
    .navbar .navbar-wrapper {
        .navbar-toggle.toggled {
            left: -20px;
            position: relative;
            right: unset;
            transition: all 1s;
        }
    }

    .navCollapse{
        justify-content: space-evenly;
        align-content: space-between;
        border-top: solid $lf-gray-l 1px;
        padding-top: 10px;
    }

    .navbarCol{
        margin-top: 10px;
    }
}
