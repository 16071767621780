.clientDetail {
    .charts {
        .row {
            margin-bottom: 15px;
        }
    }

    .btnGetBack {
        background-color: $button-red;
        border: none;
        transition: 0.5s;

        &:hover {
            background-color: lighten($button-red, 30%);
        }
    }
}

.clientFaveProdTitle {
    background-color: $cortt-blue;
    color: white;
    padding: 15px;
    border-radius: 5px;
}

.summaryInfo {
    text-align: center;

    .card {
        margin-bottom: 15px;
    }

    .iconAndName {
        .icon {
            font-size: 5em;
            color: $lf-gray-l;
        }
    }
}

.clientChart {
    margin-bottom: 15px;
}

.clientFavProd {
    margin-bottom: 15px;
}
