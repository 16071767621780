//Filter classes -------------------------------------------------------------------------------------------------
.containerFiltros {
    //padding: 15px;
    padding-bottom: 1%;
    margin-bottom: 0.5%;
    border-bottom: solid rgb(201, 201, 201) 1px;

    button {
        background-color: $button-blue;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: 1.1vw;
        width: 100%;
        padding: 10px 10px;
        margin-bottom: 15px;
        transition: .5s;

        &:hover {
            transform: scale(1.05);
            background-color: $button-blue-h;
        }
    }
}

.filterRow {
    min-height: 40px;
    height: 100%;

    .buttonCol {
        padding-left: 0px;
    }
}

.topFiltros {
    position: relative;
    z-index: 2;
    margin-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    transition: 1s;

    .btnClearFiltros {
        background-color: white;
        color: $button-blue;
        font-size: 0.8vw;
        border: solid 1px $button-blue;
        transition: 0.5s;

        &:hover {
            transform: scale(1.05);
            background-color: lighten($color: $button-blue, $amount: 40%);
        }
    }

    .btnEditFilters {
        background-color: white;
        color: $button-blue;
        font-size: 0.8vw;
        border: solid 1px $button-blue;
        transition: 0.5s;

        &:hover {
            transform: scale(1.05);
            background-color: lighten($color: $button-blue, $amount: 40%);
        }
    }
}

.xButton {
    color: $button-blue;
    cursor: pointer;
    font-weight: bold;
    font-size: 30px;
    transition: all 0.8s;
    float: right;

    &:hover {
        transform: scale(1.2);
    }
}

.appliedFilters {
    background-color: $button-blue;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.1vw;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;

    &.min {
        font-size: 0.7vw;
        padding: 14px 10px;
    }

    span {
        position: relative;
        float: right;
        margin-left: 8px;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            color: $cortt-blue;
        }
    }
}

.clearFilterCol {
    display: flex;
    max-width: 50%;

    button {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.floatR {
    text-align: right;
    padding-right: 8% !important;
}

.spaceR {
    margin-right: 35px;
}

//Estilos de los paneles de seleccion de filtros--------------------------------------------------------------------------------------------------------
.filterPanel {
    position: absolute;
    margin: 0 7.5px 0 7.5px;
    top: 100%;
    z-index: 9991;
    transform: translateZ(0px);
    background-color: $button-blue;
    border-radius: 5px;
    padding: 15px;
    max-width: 97%;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    color: white;

    p {
        color: white;
    }

    td {
        color: white;
    }

    .xButton {
        color: white;
        cursor: pointer;
        font-weight: bold;
        font-size: 30px;
        transition: all 0.8s;
        float: right;

        &:hover {
            transform: scale(1.2);
        }
    }

    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .genderFilter {
        display: flex;

        div {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            span {
                margin-right: 15px;
            }

            label {
                padding-top: 8px;
                margin: 0px;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        max-height: 60vh;
        max-width: 97%;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

.selectFilterForm {
    input {
        color: white;
        border-color: white;

        &::placeholder {
            color: white;
        }
    }

    .btnFilterSearch {
        margin: 0px;
        border: solid white 1px;
        border-radius: 5px;
        transition: 0.5s;

        &:hover {
            background-color: white;
            color: $button-blue-h;
        }
    }
}

.tableCategoryItems {
    margin-top: 20px;

    @media screen and (max-width: 1280px) {
        button {
            margin-bottom: 10px;
        }
    }

    button {
        padding: 5px 10px 5px 10px;
        margin-right: 5px;
        border: 1px solid white;
        border-radius: 5px;
        background-color: transparent;
        color: white;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            background-color: white;
            color: $cortt-blue;
        }
    }

    .spaceR {
        margin-right: 35px;
    }

    .active {
        background-color: white;
        color: $cortt-blue;
    }

    b {
        color: white;
        font-size: 16px;
    }

    .id {
        font-size: 13px;
    }

    .filterButtonConfig {
        display: flex;
        justify-content: space-evenly;
    }
}

.triangle-up {
    position: absolute;
    top: 80%;
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 50px solid $button-blue;
}


@media screen and (max-width: 991px) {
    .containerFiltros {
        //padding: 15px;
        padding-bottom: 1%;
        margin-bottom: 0.5%;
        border-bottom: solid rgb(201, 201, 201) 1px;

        button {
            background-color: $button-blue;
            border: none;
            border-radius: 5px;
            color: white;
            font-size: 2vw;
            width: 100%;
            padding: 10px 10px;
            margin-bottom: 15px;
            //height: 100%;
        }
    }
    .filterRow {
        min-height: 40px;
        height: 100%;

        .buttonCol {
            padding-left: 15px;
        }
    }

    .clearFilterCol {
        // display: flex;
        max-width: 100%;

        .btnClearFiltros {
            font-size: 2vw;
            // margin-left: 5px;
            // margin-right: 5px;
        }
    }

    .filterPanel {
        left: 2px;
    }

    .appliedFilters {
        font-size: 2vw;
    }

    .topFiltros {
        .btnClearFiltros {
            font-size: 2vw;
        }

        .btnEditFilters {
            font-size: 2vw;
        }
    }
}

@media screen and (max-width: 768px) {
}
