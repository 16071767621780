.lifeMiles-theme {
    .stdOutData{
        color: $lf-red;
    }

    .sidebar {
        color: white;
    
        .nav {
            li {
                &.active {
                    background-color: lighten($color: $lf-red-l, $amount: 15%);
                }
            }
    
            p {
                color: white;
            }
    
            i {

                color: white;
            }
        }
    
        &[data="default"] {
            background-color: $cortt-blue;
        }
        &[data="default.css"] {
            background-color: $cortt-blue;
        }
        &[data="lightTheme.css"] {
            background-color: $lf-red;
        }
    }

    .singleCard {
        .cardValue {
            color: $lf-red;
        }

        .avgTicketSubdata {
            b {
                color: $lf-red-l;
            }
        }
    }

    .globalChart {
        .stdOutData {
            color: $clear-red;
        }
    }

    .genderCount {
        h4 {
            color: $lf-red;
        }

        i {
            color: $lf-red;
        }

        .footer {
            b {
                color: $clear-red;
            }
        }
    }

    .genderProductTable{
        .stdOutData{
            color: $lf-red-l;
        }
    
        .favProd{
            b{color:$lf-light-nickel}
            label{color:$lf-gray-l}
        }
    
        .tableProductdsByGender{
            h4{color:$lf-red}
            td{font-size: 0.9em;}
        }
    }

    .globalSummary {
        .value {color: $lf-red;}
    }

    .segmentListItem {
        li {
          color: $filter-red;
          background-color: $filter-red-background;
        }
    }

    .customSegmentMinSumHorizontal {
        span {
          color: $lf-red !important;
        }
      
        small {
          color: $lf-red;
        }
    }

    .customDetailGenderSummary {
        .M{color: $lf-red;}
        .F{color: $lf-red;}
    }

    .campaignCreator{
        .emailCampaignCreatorForm{
            .emailEditor{
                .buttonContainer{
                    background-color: $lf-red;

                    button{
                        background-color: white;
                        color: $lf-red;

                        &:hover{
                            background-color: lighten($color: $lf-red-l, $amount: 25%);
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
