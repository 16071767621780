.bucketTitleRow {
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    h1 {
        font-size: 4em;
        color: $cortt-blue;
    }
}

.bucketTableHead {
    background-color: $cortt-blue;
    color: white;
    border-radius: 5px;
}

.bucketTableBody {
    button {
        background: transparent;
        border: solid 1p;
        padding: 5px 15px;
        border-radius: 10px;
        color: white;
        margin: 0px 10px;
        cursor: pointer;
        transition: 0.5s;
        border-color: $button-blue-l;
        color:$button-blue-l;

        &:hover {
            background-color: $button-blue-h;
            border-color: $button-blue-l;
            // border: none;
        }
    }
}

.bucketTable {
    td,
    th {
        text-align: center;
    }

    td {
        font-size: 10pt;
        font-weight: 600;
        color: $lf-gray-l;
    }
}

.bucketLoading {
    padding: 15px;
    p {
        text-align: center;
        margin-top: 10px;
    }
}
